<template>
    <section class="VolumeSection">
      <v-flex ma-2 style="font-size: 30px;font-weight: bold;">Surround Sound Test</v-flex>
      <v-flex>
        <v-flex class="clsForDescription" mb-2>
          <v-flex> 1 . Please make sure there should be no background noise.</v-flex>
          <v-flex> 2 . Please keep quite between the examination, If you are talking to any one and this software will detect the noise then your examination will be submited automatically.</v-flex>
          <v-flex> 3 . Please don't talk, don't use the phone, don't ask anyone for anything during the examination.</v-flex>
        </v-flex>
        <div id="content" style="width:600px;height: 200px;position:relative;margin:auto;background: #d3d3d330;"></div>
      </v-flex>
      <v-flex ma-3>
        <v-btn v-if="!Stop && !Loading && !Retest && Audio_Stream" color='primary' id="ClickBtn" @click="StartAudio">Start Your Background Noise Testing</v-btn>
        <v-progress-circular v-if="Loading" :rotate="180" :size="80" :width="10" :value="value" color="primary">
            {{ Math.floor(value) }}%
        </v-progress-circular>
        <v-flex v-if="Stop && Completed && !Retest" style="color:green;font-weight: bold;"> Great! Noise testing done successfully</v-flex>
        <v-btn color='primary' v-if="Stop && Completed && !Retest" @click="ClickHere"> Click to Proceed </v-btn>
        <v-flex v-if="Retest" style="color:red;font-weight: bold;"> Noise Testing failed, Please keep quite and make sure no noise in your background. </v-flex>
        <v-btn color='primary' v-if="Retest" @click="StartAudio"> Re-test </v-btn>
      </v-flex>
    </section>
</template>
<script>

import $ from "jquery";
var audioContext = null;
let analyser = null;
let dataArray = null;
let bufferLength = 0;
let meter = null;
import {  createAudioMeter } from '../utils/Volume-Meter'

export default {

  data(){
    return {
        Count       : 0,
        Meter       : 0,
        Stop        : false,
        Loading     : false,
        Completed   : false,
        interval    : {},
        value       : 0,
        Start_Count : false,
        Retest      : false,
        Average     : 0,
        Actual      : 0,
        Audio_Stream : false
    }
  },
  beforeDestroy () {
      clearInterval(this.interval)
  },
  computed:{
    GetVerifiedImage(){
      return this.$store.getters.GetVerifiedImage
    }
  },
  methods:{
      ClickHere(){
         this.$router.push('/login')
      },
      StartAudio(){

        clearInterval(this.interval);
        this.Meter = 0;
        this.Count = 0;
        this.value = 0;
        this.Average = 0;
        let _this = this;        
        audioContext.resume().then(() => {

          this.Loading = true
          this.Stop    = false;
          this.Start_Count = true;

          this.interval = setInterval(() => {
              if (this.value >= 99) {
                _this.Average = this.Meter / this.Count;
                  _this.Stop = _this.Average >= 40 ? false : true; 
                  _this.Start_Count = false;
                  _this.Completed = _this.Average >= 40 ? false : true;
                  _this.Retest = _this.Average >= 40 ? true : false;
                  _this.Loading = false;
                return (this.value = 0)
              }
              this.value += 3.33;
          }, 1000)

        });
      },
      didntGetStream() {
        this.Audio_Stream = false;
        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Stream Generation Failed.","Color":"red","Close": false });
      },
      gotStream(Stream){

            this.Audio_Stream = true;
            var src = audioContext.createMediaStreamSource(Stream);
            var src1 = audioContext.createMediaStreamSource(Stream);
            analyser = audioContext.createAnalyser();
            meter = createAudioMeter(audioContext);

            var gainNode  = audioContext.createGain();
            gainNode.gain.value = 0;

            src.connect(analyser);
            src1.connect(meter)

            analyser.connect(gainNode);
            gainNode.connect(audioContext.destination)
            
            analyser.fftSize = 32;
            analyser.minDecibels = -100;
            analyser.maxDecibels = 0;
        
            bufferLength = analyser.frequencyBinCount;    
            dataArray = new Uint8Array(bufferLength);

            this.renderFrame();
        },
        renderFrame(){

            var content = $('#content');
            var barWidth = 100 / bufferLength;

            for(var i = 0; i < bufferLength; i++) {
                $('<div/>').css({
                    "position":"absolute",
                    "left": i * barWidth +'%',
                    "bottom":0,
                    "width": (barWidth - 0.1) + '%',
                    "float":'left'
                }).appendTo(content)
            }

            var bars = $('#content > div')
            let _this = this;
            setInterval(function(){

                _this.Actual = Math.ceil(meter.volume * 1000)
                analyser.getByteFrequencyData(dataArray);
                if(_this.Start_Count){
                    _this.Meter+= _this.Actual
                    _this.Count++;
                } 
                bars.each(function( index , bar ){
                    $(bar).css({
                        'height' : dataArray[index].map(0,255,0,$('#content').height()) + 'px',
                        'background' : 'rgb(' + dataArray[index] + ',' + (255 - dataArray[index]) + ', 0 )'
                    })
                })

            },10)

        }
  },
  mounted(){

    if(this.GetVerifiedImage){


      Number.prototype.map = function(in_min,in_max,out_min,out_max) { return ( this - in_min ) * ( out_max - out_min ) / ( in_max - in_min ) + out_min }
      
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContext = new AudioContext();
  
      try {

        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        navigator.getUserMedia({ "audio": { "mandatory": { "googEchoCancellation": "false", "googAutoGainControl": "false","googNoiseSuppression": "false", "googHighpassFilter": "false" }, "optional": [] } }, this.gotStream, this.didntGetStream);
      
      }catch (e) {

        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Stream Generation Failed.","Color":"red","Close": false });
      
      }

    }else{

      this.$router.push('/auth')

    }

    this.$eventHub.$emit("LOADING",'false')
    
  }

}

</script>
<style scoped>
.VolumeSection{
    text-align: center;
    margin: 8%;
}
.v-progress-circular {
  font-size: 18px !important;
}
.clsForDescription{
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    width: 607px;
    margin: auto; 
}
</style>
