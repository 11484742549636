import Vue from 'vue'
import store from '../store'

export default {
    result(){

        let user_id = store.getters.GetUserInfo['id']
        let exam_id = store.getters.getExamId

        return new Promise((resolve, reject) => {
            this.getTotalResult(user_id, exam_id)
                .then(response => {
                    let totalResult = response
                    if(response.length > 0) {
                        this.getSubjectWiseResult(user_id, exam_id)
                            .then(response => {
                                store.commit('setSubjectWiseResult', response)
                                store.commit('setTotalResult', totalResult[0])
                                resolve()
                            }, error => {
                                console.log(error)
                                reject()
                            })
                    }else {
                        reject()
                    }
                }, error => {
                    console.log(error)
                    reject()
                })
        })
    },
    getTotalResult(user_id, exam_id){
        return new Promise((resolve, reject) => {
            Vue.nedb.totalResult.find({ user_id: user_id, exam_id: exam_id}, function (error, docs) {
                if(error) {
                    console.log(error)
                    reject()
                }
                else {
                    resolve(docs)
                }
            })
        })
    },
    getSubjectWiseResult(user_id, exam_id){
        return new Promise((resolve, reject) => {
            Vue.nedb.subjectWiseResult.find({ user_id: user_id, exam_id: exam_id}, function (error, docs) {
                if(error) {
                    console.log(error)
                    reject()
                }
                else {
                    resolve(docs)
                }
            })
        })
    }
}