import store from '../store'


export default {

    saveTimeTaken(){

        let currentTime = store.getters.getCurrentTime
        let questionDisplayedAt = store.getters.getDisplayedAtTime
        store.commit('setQuestionWiseTimeTaken', (parseInt(questionDisplayedAt) - parseInt(currentTime)))
    
    },
    calculateExamDuration(data){
        
        store.getters.GetUserInfo['exam_state'] == 'start' ? store.commit('setExamDuration', {duration: data.exam_duration_left}) : {}
        let time = store.getters.GetUserInfo['exam_state'] == 'start' ? data.exam_duration_left : 10 * 60 * 1000
        var d = new Date()
        d.setTime(new Date().getTime() + time)
        document.cookie = `token=${store.getters.GetUserInfo['identifier']};expires=${d.toUTCString()}`
        store.commit('SetToken',`${store.getters.GetUserInfo['identifier']}`)

    }
}