import Vue    from 'vue'
import Store  from '../store'
import Socket from '../socket/Socket'

export default {
    store(){

        let payload = { user_id: 0, exam_id: 0, subject_id: 0, question_id: 0, question_no: 0, response: '',remark: '', 
                        positive_marks: 0, negative_marks: 0, answered: 0, reviewed: 0, time: 0, updated : 0 }

        payload.user_id        = Store.getters.GetUserInfo['id']
        payload.exam_id        = Store.getters.GetUserInfo['exam_id']
        payload.subject_id     = parseInt(Store.getters.getSelectedSubject)
        payload.question_id    = Store.getters.getQuestionId
        payload.response       = Store.getters.getStudentResponse
        payload.remark         = Store.getters.getQuestionRemark
        payload.positive_marks = Store.getters.getObtainedPositiveMarks
        payload.negative_marks = Store.getters.getObtainedNegativeMarks
        payload.time           = Store.getters.getQuestionWiseTimeTaken

        
        let currentQuestion = Store.getters.getCurrentQuestionsNo
        payload.question_no = currentQuestion
        
        let answeredQuestions   = Store.getters.getAnsweredQuestions
        let unAnsweredQuestions = Store.getters.getUnAnsweredQuestions
        
        payload.answered = answeredQuestions.indexOf(currentQuestion) > -1 ? 1 : unAnsweredQuestions.indexOf(currentQuestion) > -1 ? -1 : 0
        
        payload.updated = payload.answered ? 0 : 1;

        let reviewedQuestion = Store.getters.getMarkedQuestions
        payload.reviewed = reviewedQuestion.indexOf(currentQuestion) > -1 ? 1 : 0


        return new Promise((resolve, reject) => {

            this.remove({ user_id: payload.user_id, exam_id: payload.exam_id, subject_id: parseInt(payload.subject_id), question_id: payload.question_id })
                .then(response => {

                   return this.insert(payload)
                })
                .then(response =>{

                    Socket.response_sync_individual(response)
                    resolve(response)
                })
                .catch(error =>{

                    console.log(error)
                })

        })

    },
    get(){

        let user_id = Store.getters.GetUserInfo['id']
        let exam_id = Store.getters.GetUserInfo['exam_id']

        return new Promise((resolve, reject) => {

            Vue.nedb.response.find({ user_id: user_id, exam_id: exam_id }, function (error, docs) {
                if(error) {
                    reject(error)
                }
                else {
                    resolve(docs)
                }
            })

        })
    },
    remove(payload){
        return new Promise((resolve, reject) => {

            Vue.nedb.response.remove({ user_id: payload.user_id, exam_id: payload.exam_id, question_id: payload.question_id },
                { multi: true}, function (err, numRemoved) {
                    if(err){
                        reject(err)
                    }
                    else {
                        resolve(numRemoved)
                    }
                });

        })
    },
    insert(payload){

        return new Promise((resolve, reject) => {
            Vue.nedb.response.insert(payload, function (err, doc) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(doc)
                }
            })

        })

    },
    update(payload){

        return new Promise((resolve, reject) => {
            
            Vue.nedb.response.update(payload , { $set : { updated : 1 }} , { multi : true , returnUpdatedDocs : true }, function (err, doc) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(doc)
                }
            })

        })

    },

    removeAll(){
        return new Promise((resolve, reject) => {

            Vue.nedb.response.remove({},{ multi: true}, function (err, numRemoved) {
                    if(err){
                        reject(err)
                    }
                    else {
                        resolve(numRemoved)
                    }
                });

        })
    },
}