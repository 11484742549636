import store from '../store'

export default {
    next(){
        let currentQuestion = store.getters.getCurrentQuestionsNo
        let currentSubjectQuestionCount = store.getters.getQuestionCount

        if(currentQuestion === currentSubjectQuestionCount) {
            this.changeSubject()
        }
        else {
            this.changeQuestion(currentQuestion+1)
        }
    },
    changeSubject(){

        let subjectIds = store.getters.getSubjectIds
        let currentSubject = store.getters.getSelectedSubject

        if(subjectIds.indexOf(parseInt(currentSubject)) === (subjectIds.length-1)) {
            store.commit('setSelected', subjectIds[0])
            this.changeQuestion(1)
        }
        else {

            let index = subjectIds.indexOf(parseInt(currentSubject)) + 1

            store.commit('setSelected', subjectIds[index])

            this.changeQuestion(1)
        }
    },
    changeQuestion(currentQuestion){
        store.commit('setQuestionsNumber', currentQuestion)
    }
}