import Vue from 'vue'
import store from '../store'

export default {
    total(payload){

      let count = store.getters.getTotalQuestions

      let result = this.calculate(payload, count)

      return new Promise((resolve, reject) => {
          this.result(result)
              .then(response => {
                  resolve(response)
              }, error => {
                  reject(error)
              })
      })

    },
    calculate(payload, count){

        let user_id = store.getters.GetUserInfo['id']
        let exam_id = store.getters.getExamId

        let result = {
            user_id: user_id,
            exam_id: exam_id,
            attempted: 0,
            correct: 0,
            skipped: 0,
            positive_marks: 0,
            negative_marks: 0,
            time: 0
        }

        let length = payload.length

        for(let i=0; i<length; i++) {

            if(payload[i].attempted_questions > 0) {
                result.attempted = parseInt(result.attempted) + parseInt(payload[i].attempted_questions)
            }

            if(payload[i].correct_questions > 0) {
                result.correct = parseInt(result.correct) + parseInt(payload[i].correct_questions)
            }

            if(parseInt(payload[i].correct_marks) > 0) {
                result.positive_marks =  result.positive_marks + parseInt(payload[i].correct_marks)
            }

            if(parseInt(payload[i].wrong_marks) < 0) {
                result.negative_marks = result.negative_marks + parseInt(payload[i].wrong_marks)
            }

            result.time += parseInt(payload[i].time)

        }

        result.skipped = parseInt(count) - parseInt(result.attempted)

        let data = {
            user_id: result.user_id,
            exam_id: result.exam_id,
            date: new Date(),
            allotted_time: store.getters.getExamDuration,
            total_questions: count,
            total_obtained_marks: result.positive_marks - result.negative_marks,
            correct_questions: result.correct,
            attempted_questions: result.attempted,
            skipped_questions: result.skipped,
            correct_marks: result.positive_marks,
            wrong_marks: result.negative_marks,
            overall_time: result.time,
            exam_submit_status: 1,
            exam_source: 3,
            exam_type: 'EXAM',
            exam_date: new Date(store.getters.getExamDateTime)
        }

        console.log(data)

        return data

    },
    /**
     *
     * @param payload
     * @returns {Promise}
     */
    result(payload){
        return new Promise((resolve, reject) => {
            Vue.nedb.totalResult.insert(payload, function (err, doc) {
                if(err) {
                    console.log(err)
                    reject(err)
                }
                else {
                    resolve(doc)
                }
            })
        })
    }
}