<template>
    <div class="main-container">
        <v-layout row>
                <v-flex xs5 pa-4 style="margin:auto;margin-top:10%;">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>System Login</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text style="padding-bottom: 0px;">
                                <v-form>
                                    <v-text-field label="Server IP or Domain" v-model="server_ip"></v-text-field>
                                    <v-text-field label="Server Port" v-model="server_port"></v-text-field>
                                    <v-text-field label="System Number" type='number' v-model="system_no"></v-text-field>
                                    <v-text-field label="LAB" type='number' v-model="lab"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn style="padding: 0px 30px;" color="primary" @click="SaveInfo">Save and Next</v-btn>
                            </v-card-actions>
                        </v-card>
                </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
    </div>
</template>
<script>

import io from 'socket.io-client';
import SystemDB from '../datastore/Exams';
import os from 'os'

export default {
    data(){
        return {
            server_ip   : "",
            server_port : "",
            system_no   : null,
            lab         : null
        }
    },
    computed:{
        connection(){
            return this.$store.getters.GetConnectionStatus;
        },
        GetSystem(){

            return SystemDB.get(this.$nedb.system,{});
        },
        GetSystemDetails(){

            return this.$store.getters.GetSystemDetails;
        }   
    },
    watch:{
        connection(){

            this.$eventHub.$emit("LOADING",'false')
            let Skips = this.$store.getters.GetSkips
            let Route = !Skips['Skip_Verify'] ? '/verify' : '/login';
            this.$router.push(Route)
        }   
    },
    methods:{
        SaveSystemInfo(data){

            return new Promise((resolve,reject) =>{

                this.$http.post(`${this.$store.getters.GetServer}/admin/system-details`,data)
                    .then(response =>{
                        
                        if(response && response['data'] && !response['data']['exist'] && response['data']['success']){
                            
                            resolve(true)

                        }else{
                            this.$eventHub.$emit("LOADING",'false')
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `System Number Or Lab Already Assigned`,"Color":"red","Close": false });
                        }
                    })
                    .catch(error => {
    
                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Saving ${JSON.stringify(error)}`,"Color":"red","Close": false });
                    
                    })
            })
        },
        SaveInfo(){

            let data = {
                    server_ip   : this.server_ip,
                    system_no   : parseInt(this.system_no),
                    lab         : parseInt(this.lab),
                    server_port : this.server_port,
                    ...this.GetSystemDetails
                }

            if(this.server_ip && this.server_port){

                this.$eventHub.$emit("LOADING",'true')
                this.SaveSystemInfo(data)
                    .then(response =>{

                        return SystemDB.insert(this.$nedb.system, data)
                    })
                    .then(response =>{
    
                        if(data['server_ip'] && data['server_port']){

                            this.$store.commit('SetSystemInfo' , data )
                            this.$store.commit('SetLocalURL',`${data['server_ip']}:${data['server_port']}`);
                            this.InitConfig(`http://${data['server_ip']}:${data['server_port']}`);

                        }else{

                            this.$eventHub.$emit("LOADING",'false')
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Unable Process Server IP Address and Port","Color":"red","Close": false });
                        }
    
                    })
                    .catch(error =>{

                        this.$eventHub.$emit("LOADING",'false')
                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Connecting ${JSON.stringify(error)}`,"Color":"red","Close": false });
    
                    })
            }else{

                this.$eventHub.$emit("LOADING",'false')
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Please Enter Server IP Address and Port","Color":"red","Close": false });
            }   
        },
        validatesystem(){

            SystemDB.get(this.$nedb.system,{})
                .then(response =>{
                    
                    let data = response && response.length > 0 ? response[0] : false
                    if(data && data['server_ip'] && data['server_port']){

                        this.$store.commit('SetSystemInfo' , data )
                        this.InitConfig(`http://${data['server_ip']}:${data['server_port']}`);
                        
                    }else{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Please Enter Server IP Address and Port`,"Color":"green","Close": false });
                        this.$eventHub.$emit("LOADING",'false')
                    }
                })
                .catch(error =>{

                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Error While Connecting ${JSON.stringify(error)}`,"Color":"red","Close": false });
                    this.$eventHub.$emit("LOADING",'false')
                })
        },
        ConnectSocket(URL){

            let temp = this.$store.getters.GetSystemInfo
            let system = temp && Object.keys(temp).length > 0 ? temp : { 'system_no' : Math.floor(100 + Math.random() * 900) }
            this.$Socket.close();
            let socket = io(URL , { query: `user_id=${Math.floor(100000 + Math.random() * 900000)}&first_name=${Math.floor(100000 + Math.random() * 900000)}_temp&system_no=${system['system_no']}` })
            this.$Socket.init(socket);

        },
        InitConfig(URL){

            this.$http.post(`${URL}/admin/init-config`)
                .then(response =>{

                    // let server_time = new Date("2020-10-16T09:27:00Z").toISOString(undefined, {timeZone: 'Asia/Kolkata'})
                    let server_time = new Date(response.headers.date).toISOString(undefined, {timeZone: 'Asia/Kolkata'})
                    this.$store.commit('SetServerTime',server_time)
                    console.log("Connected In System " + server_time)
                    this.ConnectSocket(`${URL}/student`)

                })
                .catch(error =>{

                    console.log(error)
                })
        }
    },
    mounted(){

        this.validatesystem() 
    }    
}
</script>
<style scoped>

</style>