<template>
    <v-layout row justify-center>
        <v-dialog v-model="submitExam" persistent scrollable max-width="50%">
            <v-card>
                <v-card-title class="headline">Finish and Submit Exam</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <h2>Do you really want to submit and finish the exam?</h2>
                    <h5>To submit exam click on submit button</h5>
                    <h5>To cancel, click on cancel button</h5>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.native="submitExam = false">Cancel</v-btn>
                    <v-btn color="primary" text @click.native="finish()">Submit & Finish Exam</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>
    import FinishExam from '../../utils/FinishExam'
    import QuestionResponse from '../../utils/QuestionResponse'
    import QuestionTime from '../../utils/Time'
    import Calculate from '../../utils/Calculate' 
    import DatastoreResponse from '../../datastore/Response'
    import Promise from 'bluebird'

    export default {
        computed: {
            submitExam: {
                get() {
                    return this.$store.getters.getSubmitExamStatus
                },
                set(value){
                    this.$store.commit('setExamSubmitStatus', value)
                }
            },
            time(){
                return this.$store.getters.getCurrentTime
            },
            connection(){
                return this.$store.getters.getConnectionStatus
            },
            student(){
                return this.$store.getters.GetUserInfo
            }
        },
        watch: {
            // time(value){
            //     if(value <= 0) {
            //         // this.$store.commit('setExamSubmitStatus', true)
            //         this.finish()
            //     }
            // }
        },
        methods: {
            finish(){
                
                this.$store.commit('StopClock')
                QuestionResponse.saveResponse()
                QuestionTime.saveTimeTaken()
                Calculate.calculate()
                this.SaveReponseToDB()
                    .then(response =>{

                        console.log(response);
                    })
                    .catch(error =>{

                        console.log(error)
                    
                    })

            },
            SaveReponseToDB(){

                return new Promise((resolve,reject) =>{
                    
                    DatastoreResponse.get()
                        .then(response =>{
    
                            let filter_data = response.filter(x => x.updated == 0 )
                            let data = {
                                "responses" : filter_data,
                                "student"   : { "user_id" : this.student['id'] , "exam_id"   : this.student['exam_id'] },
                             }
                            return this.$http.post(`${this.$store.getters.GetServer}/student/save_responses`,data)
                        })
                        .then(response =>{
    
                            let responses = response && response['data'] && response['data']['response'] ? response['data']['response'] : []
                            let info = responses.map(x => { return { 'user_id' : x['user_id'] , "exam_id" : x['exam_id'] , "question_id" : x['question_id'] , "subject_id" : x['subject_id']}  })
                            return Promise.mapSeries(info,(query) => DatastoreResponse.update(query)) 
                        })
                        .then(response =>{
    
                            resolve(true)
                        })
                        .catch(error =>{
    
                            reject(error)
                        })
                })
            }
        }
    }
</script>