<template>
    <section>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <img alt="Examin" class="shrink mr-5" :src="Logo" style="height: 45px;max-width: 160px;object-fit: scale-down;" />
            </div>
            <v-spacer></v-spacer>
            <!-- <app-time-to-start v-if="$route.name === 'instruction'"></app-time-to-start> -->
            <v-btn v-if="$route.name === 'instruction'" style="margin: 15px;background-color: #FFFFFF;color: #272727;" @click.native="StartExam()">Start Exam</v-btn>
            <app-exam-time v-if="$route.name === 'exam'"></app-exam-time>
            <v-icon v-if="connection">signal_wifi_4_bar</v-icon>
            <v-icon v-else>signal_wifi_off</v-icon>
            <v-app-bar-nav-icon v-if="$route.name === 'exam'" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <router-view></router-view>
        <app-sidebar v-if="$route.name === 'exam'"></app-sidebar>  
    </section>
</template>
<script>
import appServerTime from '@/components/ServerTime';
import appSidebar from '@/views/SideNavBar'
import appLogo from '@/assets/logo.png'
import appTimeToStart from '@/views/Time/TimeToStart.vue';
import appExamTime    from '@/views/Time/Time.vue';

export default {
    components: {
      appServerTime,
      appSidebar,
      appTimeToStart,
      appExamTime
    },
    computed: {
        Logo(){
            return this.$store.getters.GetInstituteDetails && this.$store.getters.GetInstituteDetails.image ? `https://examin.co.in/${this.$store.getters.GetInstituteDetails.image}` : appLogo
        },
        drawer: {
            get() {
                return this.$store.getters.GetDrawer;
            },
            set(value) {
                this.$store.commit('SetDrawer', value)
            }
        },
        connection(){
            return this.$store.getters.GetConnectionStatus;
        },
        status(){
            return this.$store.getters.getExamStatus
        },
        instituteDetails(){
                return this.$store.getters.GetInstituteDetails
        }
    },
    methods:{
        StartExam(){
            this.$router.push('/exam');
            this.$Socket.WriteLOGs(`${this.getUserDetails['first_name']} Exam Started at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
        }
    }
}
</script>
<style scoped>
@media (max-width: 600px) {
  .v-img {
    max-width: 100px !important;
    height: auto !important;
  }
}
</style>