<template>
    <div>
        <v-layout row wrap mr-5 ml-5 mt-3>
            <v-flex lg6 md6 sm6 style="margin:10px 0px;">
                <div style="float: left">
                    <v-btn class="small-button" style="margin:0px 5px;" outlined text color="secondary" @click="clear">Clear</v-btn>
                    <v-btn class="small-button" style="margin:0px 5px;" outlined text color="warning" @click="mark">Mark & Next</v-btn>
                </div>
            </v-flex>
            <v-flex lg6 md4 sm6 style="margin:10px 0px;">
                <div style="float: right;">
                    <v-btn class="small-button" style="margin:0px 5px;" outlined text color="primary" @click="previous">Previous</v-btn>
                    <v-btn class="small-button" style="margin:0px 5px;" outlined text color="success" @click="next">save & Next</v-btn>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import ExamControlNext from '../../utils/ExamControlNext'
    import ExamControlPrevious from '../../utils/ExamControlPrevious'
    import ExamControlMark from '../../utils/ExamControlMark'
    import ExamControlClear from '../../utils/ExamControlClear'
    import QuestionControl from '../../utils/QuestionControl'
    import QuestionResponse from '../../utils/QuestionResponse'
    import QuestionTime from '../../utils/Time'
    import DatastoreResponse from '../../datastore/Response'
    import Calculate from '../../utils/Calculate'

    export default {
      created() {
          this.$eventHub.$on('SET_NEXT_QUESTION', this.next);
      },
      beforeDestroy() {
          this.$eventHub.$off('SET_NEXT_QUESTION');
      },
      computed:{
 
      },
      methods: {
        next(){
            QuestionResponse.saveResponse()
            QuestionTime.saveTimeTaken()
            Calculate.calculate()
            DatastoreResponse.store()
            this.$store.commit('clearMarked', this.$store.getters.getCurrentQuestionsNo)
            ExamControlNext.next()
            QuestionControl.change()
        },
        previous() {
            QuestionResponse.saveResponse()
            QuestionTime.saveTimeTaken()
            Calculate.calculate()
            DatastoreResponse.store()
            ExamControlPrevious.previous()
            QuestionControl.change()
        },
        mark(){
            ExamControlMark.mark()
            QuestionTime.saveTimeTaken()
            Calculate.calculate()
            DatastoreResponse.store()
            ExamControlNext.next()
            QuestionControl.change()
        },
        clear(){
            ExamControlClear.clear()
        }
      }
    }
</script>

<style>
.small-button {
  /* Default button size */
  font-size: 14px;
  padding: 8px 16px;
}

@media (max-width: 600px) {
  .small-button {
    padding: 0px !important;
    width: 80px;
    font-size: 9px !important;
    margin: 2px !important;
    font-weight: bold;
  }
}
</style>