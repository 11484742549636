<template>
    <v-snackbar :timeout="3000" left absolute shaped bottom :color="color" v-model="show" style="z-index: 999999 !important;">
        {{ text }}
        <v-btn v-if="close" dark text @click.native="show = false" icon>
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
</template>
<script>
export default {
    data(){
        return {
            show  : false,
            text  : '',
            color : '',
            close : false
        }
    },
    created() {
        this.$eventHub.$on('SNACK_BAR', this.SetSnackBar);
    },
    beforeDestroy() {
        this.$eventHub.$off('SNACK_BAR');
    },
    methods: {

        SetSnackBar(SnackDetails){
            this.show  = SnackDetails['Show']
            this.text  = SnackDetails['Text']
            this.color = SnackDetails['Color']
            this.close = SnackDetails['Close']            
        }
    }
}
</script>
<style>

</style>