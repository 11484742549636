<template>
    <section>
        <video id="stream" :class="[ SetCameraPosition ? 'system' : 'normal' , Image ? 'dispay_none' : '']"></video>
        <div v-if="Image">
            <img :src="Image" class="imageCls" />
        </div>
        <canvas id="capture" style="display: none;" width="320" height="240"></canvas>
        <v-flex v-if="Webcam" style="text-align: center;">
            <v-btn v-if="!Image" color='primary' @click="take_student_image">Capture</v-btn>
            <v-btn v-else color='primary' @click="Retry">Recapture</v-btn>
        </v-flex>
    </section>
</template>
<script>
let cameraStream;
let interval_lock = null;
import axios   from 'axios';
var shutter = new Audio();
shutter.autoplay = true;
shutter.src = require('../assets/camera.mp3');

export default {
    data(){
        return {
            Image  : "",
            Webcam : false
        }
    },
    computed:{
        User(){
            return this.$store.getters.GetUserInfo
        },
        SetCameraPosition(){
            return true
        }
    },
    methods:{
        take_snapshot() {
            let _this = this
            var capture = document.getElementById( "capture" );
            if( null != cameraStream ) {
                var ctx = capture.getContext( '2d' );
                var img = new Image();
                ctx.drawImage( stream, 0, 0, capture.width, capture.height );
                // capture.toDataURL("image/png") ? _this.UploadImage(capture.toDataURL("image/png")) : {};
            }
        },
        take_student_image(){

            shutter.play();
            var capture = document.getElementById( "capture" );
            var stream = document.getElementById("stream");
            if( null != cameraStream ) {
                var ctx = capture.getContext('2d');
                var img = new Image();
                ctx.drawImage( stream, 0, 0, capture.width, capture.height );
                this.Image = capture.toDataURL("image/png")
                this.$eventHub.$emit("IMAGE_STATUS", this.Image);
                this.SetDisableCam()
                this.$store.commit('SetVerifiedImage',this.Image)
            }
        },
        Retry(){
            this.Image = ""
            cameraStream = null;
            this.Start_Streaming()
        },
        UploadImage(image){

            // if(!this.$store.getters.GetUpload){

            //     let User = this.$store.getters.GetUserInfo
            //     axios.post(`${this.$store.getters.getServer}/upload_image`,{ "User_Image": image , ...User })
            //         .then(response =>{
                        
            //             if(response && response['data'] && response['data']['response'] && response['data']['response']['images'] && 
            //                 response['data']['response']['images'].length > 0){
    
            //                 this.$Socket.SendScreenShot({ ...User , Image : response['data']['response']['images'][response['data']['response']['images'].length - 1]})
            //             }
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         })
            // }
        },
        Start_Streaming(){

            var stream = document.getElementById("stream");
            navigator.mediaDevices.getUserMedia( { video: true } )
                .then(( mediaStream ) => {
                    
                    this.Webcam = mediaStream ? true : false;
                    this.$eventHub.$emit("WEBCAM_STATUS",mediaStream ? true : false );
                    cameraStream = mediaStream;
                    stream.srcObject = mediaStream;
                    return stream.play();
                })
                .then(_ => {
                    
                    console.log(_)
                })
                .catch(( err ) => {

                    console.log( "Unable to access camera: " + err );
                });
        },
        SetDisableCam(){

            var stream = document.getElementById("stream");

            if( null != cameraStream ) {

                var track = cameraStream.getTracks()[ 0 ];
                track.stop();
                stream.load();
                cameraStream = null;
            }
    
        }
    },
    mounted(){

        this.Start_Streaming()
    },
    destroyed() {
            clearInterval(interval_lock);
    },
    created() {
        let _this = this;
        interval_lock = setInterval(() => {
            let User_Info = this.$store.getters.GetUserInfo
            User_Info && User_Info['email'] && User_Info['id'] ? _this.take_snapshot() : ""
        }, 1000);
    },
}
</script>
<style >
.normal {
    height: 108px !important;
    width: 169px !important;
    transform: scaleX(-1.12) !important;
    padding-top: 5px;
}
.system {
    /* height: 540px !important; */
    width: 95% !important;
    transform: scaleX(-1) !important;
}
.dispay_none{
    display: none;
}
.imageCls{
    /* height: 540px !important; */
    width: 95% !important;
    transform: scaleX(-1) !important;
}
</style>