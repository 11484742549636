const state = {

    Loading        : false,
    Local_URL      : '',
    Production_URL : 'https://test-series-server.examin.online',
    Dev_URL        : "http://localhost:3003",
    Skip_System    : process.env.VUE_APP_BUILD_TYPE == 'Web' ? true : false,
    Skip_Verify    : false,
    Token          : "",
    Server_Time    : null,
    Updated_Server_Time : null,
    Login_Form     : [ {"name" : "first_name" , "label" : "Roll No" , 'type' : "text" } , {"name" : "password" , "label" : "Password" , 'type' : "password" } ],
    drawer         : true,
    submit         : {},
    connection_status : false,
    system_info    : {},
    system_details : {},
    mock_exam      : false,
    displayed_result : {},
    Blured_Count     : 0,
    Focused_Count    : 0,
    temp_admin       : {},
    skip_upload      : false,
    Institute_Details : {},
    Institution_List : []
  }
  
  const mutations = {

      SetProgress(state,payload){
        state.Progress = payload
      },
      SetLoading(state,payload){
        state.Loading = payload
      },
      SetLocalURL(state , payload){
        state.Local_URL = `http://${payload}`
      },
      SetToken(state,payload){
        state.Token = payload
      },
      SetLoginForm(state,payload){
        state.Login_Form = payload
      },
      SetServerTime(state,payload){
        state.Server_Time = payload
      },
      SetUpdatedServerTime(state,payload){
        state.Updated_Server_Time = payload
      },
      SetDrawer(state,payload){
        state.drawer = payload
      },
      SetSubmit(state,payload){
        state.submit = payload
      },
      SetConnectionStatus(state,payload){
        state.connection_status = payload
      },
      SetSystemInfo(state,payload){
        
        state.system_info = payload
      },
      SetSystemDetails(state,payload){
        state.system_details = payload
      },
      SetMockExam(state,payload){
        state.mock_exam = payload
      },
      SetDisplayedResult(state,payload){
        state.displayed_result = payload
      },
      SetBlured(state, payload) {
        state.Blured_Count++;
      },
      SetFocused(state, payload) {
        console.log("Focused", state.Focused_Count)
        state.Focused_Count++;
      },
      SetVerify(state, payload){
        state.Skip_Verify = payload
      },
      SetTempAdmin(state, payload){
        state.temp_admin = payload
      },
      SetUpload(state, payload){
        state.skip_upload = payload
      },
      SetInstituteDetails(state, payload){
        state.Institute_Details = payload
      },
      SetInstitutionList(state, payload){
        console.log('Institution List', payload)
        state.Institution_List = payload
      }

  }
  
  const actions = {
      
  }
  
  const getters = {

    GetServer(state){

        if(process.env.NODE_ENV == 'development'){

          return state.Dev_URL;
        }
        else if(process.env.VUE_APP_BUILD_TYPE == 'Web') {

          return state.Production_URL;

        }else{

          return state.Local_URL ? state.Local_URL : state.Dev_URL ;

        }
    },
    GetLoading(state){
        return state.Loading;
    },
    GetSkips(state){
      return {  'Skip_System' : state.Skip_System ,  'Skip_Verify' : state.Skip_Verify };
    },
    GetToken(state){
      return state.Token;
    },
    GetLoginForm(state){
      return state.Login_Form
    },
    GetServerTime(state){
      return state.Server_Time
    },
    GetUpdatedServerTime(state){
      return state.Updated_Server_Time
    },
    GetDrawer(state){
      return state.drawer
    },
    GetSubmit(state){
      return state.submit
    },
    GetConnectionStatus(state){
      return state.connection_status
    },
    GetSystemInfo(state){
      return state.system_info
    },
    GetSystemDetails(state){
      return state.system_details
    },
    GetMockExam(state){
      return state.mock_exam
    },
    GetDisplayedResult(state){
      return state.displayed_result
    },
    GetBlured(state) {
      return state.Blured_Count;
    },
    GetFocused(state) {
      return state.Focused_Count;
    },
    GetTempAdmin(state){
      return state.temp_admin
    },
    GetUpload(state){
      return state.skip_upload
    },
    GetInstituteDetails(state){
      return state.Institute_Details
    },
    GetInstitutionList(state){
      return state.Institution_List
    }

  }
  
  export default {
    state,
    mutations,
    actions,
    getters
  }
  