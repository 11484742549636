export default class Calculation {

    /**
     * Check whether answer matches to the answer provided
     */
    calculate(response, answer, positive, negative, type, markingScheme) {

        if ("undefined" === typeof response || "undefined" === typeof answer || "undefined" === typeof positive || isNaN(positive) || "undefined" === typeof negative || isNaN(negative))
            return 0;

        if ("undefined" === typeof markingScheme || Object.keys(markingScheme).length <= 0)
            markingScheme = '';

        /**
         * Converting data to json object for MAT type questions or else converting it to array for others,
         * it would not convert single responses (i,e for SC, FILL) to array.
         */

        try {

            response = this.encodeToJSONObject(response);
            answer = this.encodeToJSONObject(answer);

        } catch (error) {

            try {

                response = this.toArray(response);
                answer = this.toArray(answer);

                /**
                 * Converting data to string, i.e. Numeric to string  for SC and fill type comparision
                 */
                if (!Array.isArray(response))
                    response = response.toString();

                if (!Array.isArray(answer))
                    answer = answer.toString();


            } catch (err) {

            }
        }


        /**
         * Checking/Calculating answer/marks for MAT
         */
        if ((this.isObject(response) && this.isObject(answer)))
            return this.compareObject(response, answer, positive, negative, type, markingScheme);

        /**
         * Checking/Calculating answer/marks for MCQ and PAR
         */
        else if (Array.isArray(response) && Array.isArray(answer))
            return this.compareArray(response, answer, positive, negative, type, markingScheme);

        /**
         * Checking/Calculating answer/marks for SC and FILL
         */
        else if ((response instanceof String && answer instanceof String))
            return this.compareString(response, answer, positive, negative);

        /**
         * If Above condition does not satisfies then considering it as wrong answer
         */
        else
            return -1 * negative;

    }

    /**
     * Calculating mark for MAT
     *
     * @param response
     * @param answer
     * @param positive
     * @param negative
     * @param type
     * @param markingScheme
     * @returns {*}
     */
    compareObject(response, answer, positive, negative, type, markingScheme) {

        let correct = 0;

        if (!this.isObject(response) || !this.isObject(answer)) {
            return negative;
        }

        for (let options in response) {

            correct += this.calculate(response[options], answer[options], 1, 0, '', '');

        }

        if (correct > 0) {

            if (this.isObject(markingScheme))
                return markingScheme[correct];
            else
                return Math.ceil((correct / Object.keys(answer).length) * positive);

        }

        return -1 * negative;

    }

    /**
     * Compare array with or without partial correct scheme.
     *
     * @param response [Array]
     * @param answer [Array]
     * @param positive [Number]
     * @param negative [Number]
     * @param type [Question type as defined: PAR, MAT, SC, FILL, MCQ etc.]
     * @param markingScheme [Object]
     * @returns {*} marks from marking scheme if markingScheme is available and PAR
     * type is applied or return positive marks when correct or else will return negative marks.
     *
     * This function would be used during calculation of MAT type questions...
     * Passing response=[Array], answer=[Array], positive=1, negative=0, type='', markingScheme=''
     * would return whether its correct or not by returning '1' or '0'.
     */
    compareArray(response, answer, positive, negative, type, markingScheme) {

        let totalResponses = response.length;
        let totalAnswer = answer.length;
        let matched = 0;

        if (totalResponses <= 0)
            return negative;

        if (!Array.isArray(response) || !Array.isArray(answer)) {
            return negative;
        }

        /**
         * Gathering total number of correct answers
         */

        response.map((option) => {

            if (answer.indexOf(option) >= 0) {

                matched++;

            }

        });

        /**
         * Calculation for partial marking type questions
         */

        if (matched > 0) {

            if (this.isObject(markingScheme)) {

                return markingScheme[matched];

            } else {

                return Math.ceil((matched / Object.keys(answer).length) * positive);

            }
        }


        /**
         * Calculation for MCQ type questions without PAR
         */
        else {

            if (totalAnswer === matched)
                return positive;

        }

        return -1 * negative;

    }

    /**
     * String comparision for SC and FILL question type.
     *
     * @param response
     * @param answer
     * @param positive
     * @param negative
     * @returns {*}
     */
    compareString(response, answer, positive, negative) {

        let _response = response.toString().trim().toLowerCase();
        let _answer = answer.toString().trim().toLowerCase();

        if (_response == _answer)
            return positive;

        return -1 * negative;

    }


    /**
     * Converts comma separated string (Only if any other delimiter is provided) to array OR
     * to json structure if condition satisfied.
     *
     * @param string
     * @param delimiter
     * @returns {*} JSON | Array | String
     */
    toArray(string, delimiter = ',') {

        if ('string' === typeof string || string instanceof String) {

            if (string.indexOf(delimiter) > 0) {

                let data = string.split(delimiter);
                if (Array.isArray(data))
                    return data;

            }

        }

        return string;

    }

    /**
     * Converting comma separated value (which is separated by hyphen) to json structure
     *
     * @param string a-p,a-q,a-r,b-p,b-q
     * @returns {*} {a:[p,q,r],b:[p,q]}
     */
    encodeToJSONObject(string) {

        let jsonObject = {};

        let array = string;

        let options;

        if ('string' === typeof string || string instanceof String) {

            jsonObject = {};

            array = string.split(',');

        }

        if (Array.isArray(array)) {

            array.map((data) => {

                    options = data.split('-');

                    if (undefined === jsonObject[options[0]])
                        jsonObject[options[0]] = [];

                    if (jsonObject[options[0]].indexOf(options[1]) === -1)
                        jsonObject[options[0]].push(options[1]);

                }
            );

            return jsonObject;

        }

        return string;

    }

    /**
     * Encoding json object of json format {a:[p,q,r],b:[p,q]} to string
     * @param object
     * @returns {string} a-p,a-q,a-r,b-p,b-q
     */
    encodeToString(object) {

        let json = object;

        let decoded = [];

        if ('string' === typeof object || object instanceof String)
            json = JSON.parse(object);

        let data;


        //TODO: Manipulate it through Array.map method
        for (let name in json) {

            for (let option in json[name]) {

                data = name + "-" + json[name][option];

                decoded.push(data);

            }

        }

        return decoded.join(',');

    }

    // Returns if a value is an object
    isObject(value) {
        return value && typeof value === 'object' && value.constructor === Object;
    }

}