<template>
    <v-dialog v-model="dialog" persistent max-width="890">
        <v-card id="backgroundcard" style="text-align:center;">
            <v-card-title class="headline">{{ Message }}</v-card-title>
            <v-card-actions style="margin: auto; width: 20%;">
                <v-btn color="primary" @click="dialog = false">Agree & Continue</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data(){
        return { 
            dialog   : false,
            Message  : 'Message From Admin',
        }
    },
    created() {
        this.$eventHub.$on('MESSAGE', this.SetLoading);
    },
    beforeDestroy() {
        this.$eventHub.$off('MESSAGE');
    },
    methods: {
        SetLoading(Loading){
            this.dialog  = Loading.dialog      
            this.Message = Loading.Message
        }
    }
}
</script>
<style scoped>

</style>