<template>
    <v-container fluid>
         <v-row justify="center">
            <v-dialog v-model="questionsDialog" persistent scrollable max-width="700">
                <v-card>
                    <v-card-title class="headline">
                        <v-flex>
                            <span>Questions </span>
                            <v-icon style="float:right;cursor:pointer;" @click.native="questionsDialog = false">close</v-icon>
                        </v-flex>
                    </v-card-title>
                    <v-divider style="margin:10px 0px;"></v-divider>
                    <v-card-text>
                        <div v-for="(question, i) in questions" class="question" @click="changeQuestion(i+1)" :key="i">
                            <div style="background: #42424217;border-radius: 5px 5px 0px 0px;padding: 0px 6px;">
                                <v-chip label style="margin:10px 2px;" color="primary" text-color="white">{{ i+1 }}</v-chip>
                                <v-chip label style="margin:10px 2px;" color="orange" text-color="white">{{ question.question_type | questionType }}</v-chip>
                            </div>
                            <v-divider style="margin:10px 0px;margin-top: 0;"></v-divider>
                            <div v-if="question.locale[language]"
                                    v-html="convertText(question.locale[language].question_text)"></div>
                            <div v-else>Question is not available in the selected language, please change the language</div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>
<script>

    import QuestionControl from '../../utils/QuestionControl'
    import DatastoreResponse from '../../datastore/Response'
    import QuestionTime from '../../utils/Time'
    import Calculate from '../../utils/Calculate'

    export default {
        computed: {
            questions(){
                return this.$store.getters.getSelectedSubjectQuestions
            },
            questionsDialog: {
                get() {
                    return this.$store.getters.getQuestionsDialogStatus
                },
                set(value){
                    this.$store.commit('setQuestionsDialogStatus', value)
                }
            },
            language(){
                return this.$store.getters.getSelectedLanguage
            }
        },
        filters: {
            questionType(type){
                if('fill' === type) {
                    return 'Fill in Blank'
                }

                if('MCQ' === type) {
                    return "Multiple Choice"
                }

                if('PAR' === type) {
                    return "Multiple Choice with Partial Marking"
                }

                if('SC' === type) {
                    return 'Single Choice'
                }

                if('MAT' === type) {
                    return 'Matrix'
                }
            }
        },
        methods: {
            changeQuestion(value){

                console.log(value)

                QuestionTime.saveTimeTaken()
                Calculate.calculate()
                DatastoreResponse.store()
                this.$store.commit('setQuestionsNumber', value)
                QuestionControl.change()
            }
        }
    }
</script>
<style scoped>
    .question{
        margin: 10px 0px;
        border: 1px solid #d3d3d361;
        padding: 5px;
        overflow: auto;
    }
    .question:hover {
        cursor: pointer;
    }
</style>