import Questions from './Questions'
import Vue from 'vue'
import _   from 'underscore'

const state = {
    total_questions_and_response: {},
    total_subjects : [],
    total_subjects_count :0
}

const mutations = {
    setFinalQuestions(state, payload) {
        let questions = Questions.state.subject_wise_questions
        for (let key in questions) {
            let data = questions[key];
            let unsortedArr = [];
            for(let j=0; j< data.length; j++){
                let currentQuestion = {
                    "question_id" : data[j].question_id,
                    "question_no" : data[j].original_question_no,
                    "response"    : "",
                    "subject_id"  : data[j].subject_id
                }
                unsortedArr.push(currentQuestion)
            }
            let sortedArray = _.sortBy(unsortedArr, function(curr){ return curr.question_no;});
            Vue.set(state.total_questions_and_response, key,sortedArray);
            state.total_subjects.push(key) 
            state.total_subjects_count += 1
        }
    },
    setFinalAnswers(state, payload){
        for (let i=0; i<payload.length; i++) {
            let data = state.total_questions_and_response;
            for(let key in data){
                let current = data[key]
                for(let j =0 ; j<current.length;j++){
                    if(current[j]["question_id"] == payload[i]["question_id"]){

                        state.total_questions_and_response[key][j]["response"] = payload[i]['response'] ? payload[i]['response'] : "";
    
                    }
                }
            }
        }
    }
}

const actions = {
}

const getters = {
    getTotalSavedResponse(state){
        return state.total_questions_and_response
    },
    getTotalSubjectsSavedResponse(state){
        return state.total_subjects
    },
    getTotalSubjectsSavedResponse(state){
        return state.total_subjects_count
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
