import Subject from './Subjects'
import Vue from 'vue'



const state = {
    answered: {}
}

const mutations = {
    setAnswered(state, payload){
        if(state.answered[Subject.state.selected_subject] &&
            state.answered[Subject.state.selected_subject].indexOf(payload) === -1) {
            state.answered[Subject.state.selected_subject].push(payload)
            
        }
        else if(state.answered[Subject.state.selected_subject] &&
            state.answered[Subject.state.selected_subject].indexOf(payload) > -1) {
            return

        }
        else {

            Vue.set(state.answered, Subject.state.selected_subject, [payload])

        }
    },
    clearAnswered(state, payload){
        if(state.answered[Subject.state.selected_subject] &&
            state.answered[Subject.state.selected_subject].indexOf(payload) > -1) {

            let index = state.answered[Subject.state.selected_subject].indexOf(payload)

            state.answered[Subject.state.selected_subject].splice(index, 1);

        }
    },
    setAnsweredFromStorage(state, payload){

        for(let i=0; i<payload.length; i++) {

            if (payload[i].answered === 1) {

                if (state.answered[payload[i].subject_id] &&
                    state.answered[payload[i].subject_id].indexOf(payload[i].question_no) === -1) {

                    state.answered[payload[i].subject_id].push(payload[i].question_no)

                }
                else if (state.answered[payload[i].subject_id] &&
                    state.answered[payload[i].subject_id].indexOf(payload[i].question_no) > -1) {

                    return

                }
                else {

                    Vue.set(state.answered, payload[i].subject_id, [payload[i].question_no])

                }
            }
        }
    }
}

const actions = {
}

const getters = {
    getAnsweredQuestions(state){
        if(state.answered[Subject.state.selected_subject]) {
            return state.answered[Subject.state.selected_subject]
        }
        else {
            return []
        }
    },
    getAllAnsweredQuestions(state){
        return state.answered
    },
    getAnsweredQuestionsCount(state){
        if(state.answered[Subject.state.selected_subject]) {
            let answeredLen = state.answered[Subject.state.selected_subject].length;
            return answeredLen
        }
        else {
            return 0
        }
    },
    getAllAnsweredQuestionsCount(state){
        let answeredLen = 0
        for(let key in state.answered){
            answeredLen += state.answered[key].length;
        }
        return answeredLen
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
