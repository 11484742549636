<template>
    <!-- <v-layout row wrap id="student"> -->
        <v-flex id="student">
            <h5>Name: {{ user.last_name }}</h5>
            <h5>Roll No: {{ user.first_name }}</h5>
        </v-flex>
    <!-- </v-layout> -->
</template>
<script>
    export default {
        computed: {
            user(){
                return this.$store.getters.GetUserInfo
            }
        },
        created(){
            
        }
    }
</script>
<style scoped>
    #student {
        /* height: 14%;
        overflow: auto;
        border-bottom: 1px solid lightgray */
        margin: 6px 15px;
    }
     img {
        max-height: 70px;
        max-width: 100px;
    }
</style>