import store       from '../store'
import Calculation from './Calculation'

export default {
    calculate(){

        let response = store.getters.getStudentResponse
        let question = store.getters.getQuestionDetails

        let calculate = new Calculation()

        let result = calculate.calculate(response, question.correct_answer,
                                question.right_marks, question.wrong_marks, question.question_type, question.marking_scheme)

        store.commit('setObtainedMarks', result)
    }
}