import Vue from 'vue'
import store from '../store'

export default {
    subject(){
        let user_id = store.getters.GetUserInfo['id']
        let exam_id = store.getters.getExamId
        let subjects = store.getters.getSubjectIds
        let question_count = store.getters.getSubjectWiseQuestionCount
        let length = subjects.length

        let subjectWiseResult = []

        console.log(subjects)

        return new Promise((resolve, reject) => {

            for (let i=0; i<length; i++){

                console.log(subjects[i])

                this.get(user_id, exam_id, parseInt(subjects[i]))
                    .then(response => {
                            let result = this.calculate(response, question_count[subjects[i]], subjects[i])
                            subjectWiseResult.push(result)
                            this.result(result)
                                .then(response => {
                                    if(i === length-1){
                                        resolve(subjectWiseResult)
                                    }
                                }, error => {
                                        console.log(error)
                                })
                    }, error => {
                        console.log(error)
                    })
            }

        })
    },
    /**
     *
     * @param user_id
     * @param exam_id
     * @param subject_id
     * @returns {Promise}
     */
    get(user_id, exam_id, subject_id){
        return new Promise((resolve, reject) => {
            Vue.nedb.response.find({ user_id: user_id, exam_id: exam_id, subject_id: subject_id }, function (error, docs) {
                if(error) {
                    reject(error)
                }
                else {
                    console.log(docs)
                    resolve(docs)
                }
            })
        })
    },
    calculate(payload, count, subject_id){

        let user_id = store.getters.GetUserInfo['id']
        let exam_id = store.getters.getExamId

        let result = {
            user_id: user_id,
            exam_id: exam_id,
            subject_id: subject_id,
            attempted: 0,
            correct: 0,
            skipped: 0,
            positive_marks: 0,
            negative_marks: 0,
            time: 0
        }

        let length = payload.length

        for(let i=0; i<length; i++) {

            if(payload[i].answered === 1) result.attempted++

            if(parseInt(payload[i].positive_marks) > 0) {
                result.correct++
                result.positive_marks =  result.positive_marks + parseInt(payload[i].positive_marks)
            }

            if(parseInt(payload[i].negative_marks) < 0) {
                result.negative_marks = result.negative_marks + parseInt(payload[i].negative_marks)
            }

            result.time += parseInt(payload[i].time)

        }

        result.skipped = parseInt(count) - parseInt(result.attempted)


        let formattedResult = {
            user_id: result.user_id,
            exam_id: result.exam_id,
            subject_id: result.subject_id,
            total_questions: count,
            max_marks: 10,
            marks_obtained: result.positive_marks - result.negative_marks,
            correct_questions: result.correct,
            attempted_questions: result.attempted,
            skipped_questions: result.skipped,
            correct_marks: result.positive_marks,
            wrong_marks: result.negative_marks,
            time: result.time
        }

        if(formattedResult.max_marks > 0) {
            formattedResult.percentage = (formattedResult.marks_obtained * 100) / formattedResult.max_marks
        }
        else {
            formattedResult.percentage = 0
        }

        return formattedResult

    },
    /**
     *
     * @param payload
     * @returns {Promise}
     */
    result(payload){

        return new Promise((resolve, reject) => {
            Vue.nedb.subjectWiseResult.insert(payload, function (err, doc) {
                if(err) {
                    console.log(err)
                    reject(err)
                }
                else {
                    resolve(doc)
                }
            })
        })
    }
}