<template>
    <div v-html="question" style="padding:15px 4%;"
         :style="{fontSize: fontSize + 'px'}"></div>
</template>
<script>
    export default {
        computed: {
            question(){
                return this.convertText(this.$store.getters.getQuestionText)
            },
            fontSize(){
                return this.$store.getters.getfontSize;
            }
        },
        mounted(){
            this.$store.dispatch('Clock')
        }
    }
</script>