import exam from './Exam'

const state = {
    response: '',
    correct_answer: '',
    essay_id: false,
    marking_scheme: {},
    locale: {},
    question_id: '',
    question_type: '',
    right_marks: '',
    subject_id: '',
    wrong_marks: '',
    essay: '',
    sc: '',
    fill: '',
    mcq_par_mat: [],
    mat: [],
    obtained_positive_marks: 0,
    obtained_negative_marks: 0,
    remark: '',
    displayed_at: 0
}

const mutations = {
    setActiveQuestion(state, payload) {

        state.response= ''
        state.correct_answer= ''
        state.essay_id= false
        state.marking_scheme= {}
        state.locale= {}
        state.question_id= ''
        state.question_type= ''
        state.right_marks= 0
        state.subject_id= 0
        state.wrong_marks= 0
        state.essay= ''
        state.sc= ''
        state.fill= ''
        state.mcq_par_mat= []
        state.mat= []
        state.obtained_positive_marks= 0
        state.obtained_negative_marks= 0
        state.remark= ''
        state.displayed_at= 0



        state.correct_answer = payload.correct_answer,
        state.essay_id = payload.essay_id,
        state.locale = payload.locale,
        state.question_id = payload.question_id,
        state.question_type = payload.question_type,
        state.right_marks = payload.right_marks,
        state.subject_id = payload.subject_id,
        state.wrong_marks = payload.wrong_marks

        if (payload.marking_scheme) {
            state.marking_scheme = payload.marking_scheme
        }
        else {
            state.marking_scheme = {}
        }

    },
    setEssay(state, payload) {
      state.essay = payload
    },
    setScResponse(state, payload){
        state.sc = payload
    },
    setMcqResponse(state, payload){

        let status = payload.status
        let value = payload.value

        if(status === false) {
            if(state.mcq_par_mat.indexOf(value) > -1) {
                state.mcq_par_mat.splice(state.mcq_par_mat.indexOf(value), 1)
            }
        }
        else {
            state.mcq_par_mat.push(value)
        }
    },
    setFillResponse(state, payload){
        state.fill = payload
    },
    initializeMcqResponse(state, payload){
        state.mcq_par_mat = payload
    },
    clearQuestionResponse(state, payload){
        state.sc = ''
        state.fill = ''
        state.mcq_par_mat = []
        state.mat = []
    },
    setMatResponse(state, payload){
        state.mat = payload
    },
    setQuestionDisplayedAt(state, payload){
        state.displayed_at = payload
    },
    setObtainedMarks(state, payload){
        if(payload > 0) {
            state.obtained_positive_marks = payload
            state.obtained_negative_marks = 0
        }
        else {
            state.obtained_negative_marks = payload
            state.obtained_positive_marks = 0
        }
    }
}

const actions = {
}

const getters = {
    getQuestionId(state) {
        return state.question_id
    },
    getQuestionResponse(state) {

        if(state.question_type === 'SC') {
            return state.sc
        }

        if(state.question_type === 'fill') {
            return state.fill
        }

        if(state.question_type === 'MCQ' ||
            state.question_type === 'PAR') {
            return state.mcq_par_mat
        }

        if(state.question_type === 'MAT') {
            return state.mat
        }

    },
    getQuestionText(state){

        if(state.locale[exam.state.selected_language] === undefined) {
            return "Question is not available in the selected language, please change the language"
        }

        return state.locale[exam.state.selected_language]['question_text']
    },
    getEssayId(state){

        return state.essay_id
    },
    getOptions(state){

        if(state.locale[exam.state.selected_language] === undefined) {
            return false
        }

        return state.locale[exam.state.selected_language]['options']
    },
    getScResponse(state){
        return state.sc
    },
    getMcqResponse(state){
        return state.mcq_par_mat
    },
    getQuestionType(state){
        return state.question_type
    },
    getFillResponse(state){
        return state.fill
    },
    getMatResponse(state){
        return state.mat
    },
    getMatSectionALength(state){

        if(state.locale[exam.state.selected_language] === undefined) {
            return false
        }


        if(state.question_type !== 'MAT') {
            return 0
        }
        let array = state.locale[exam.state.selected_language]['options']['section_a']

        let count = 0

        for(let i=0; i<array.length; i++) {

            if (array[i].text && array[i].text.trim() !== '') {
                count++
            }
        }

        return count;

    },
    getMatSectionBLength(state){


        if(state.locale[exam.state.selected_language] === undefined) {
            return false
        }

        if(state.question_type !== 'MAT') {
            return 0
        }

        let array = state.locale[exam.state.selected_language]['options']['section_b']

        let count = 0

        for(let i=0; i<array.length; i++) {

            if (array[i].text && array[i].text.trim() !== '') {
                count++
            }
        }

        return count;

    },
    getQuestionRemark(state){
        return state.remark
    },
    getObtainedPositiveMarks(state){
        return state.obtained_positive_marks
    },
    getObtainedNegativeMarks(state){
        return state.obtained_negative_marks
    },
    getDisplayedAtTime(state){
        return state.displayed_at
    },
    getQuestionDetails(state){
        return state
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
