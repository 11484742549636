import Subject from './Subjects'
import Vue from 'vue'


const state = {
    unanswered: {}
}

const mutations = {
    setUnAnswered(state, payload){
        if(state.unanswered[Subject.state.selected_subject] &&
            state.unanswered[Subject.state.selected_subject].indexOf(payload) === -1) {

            state.unanswered[Subject.state.selected_subject].push(payload)

        }
        else if(state.unanswered[Subject.state.selected_subject] &&
            state.unanswered[Subject.state.selected_subject].indexOf(payload) > -1) {
            return

        }
        else {

            Vue.set(state.unanswered, Subject.state.selected_subject, [payload])

        }
    },
    clearUnAnswered(state, payload) {
        if(state.unanswered[Subject.state.selected_subject] &&
            state.unanswered[Subject.state.selected_subject].indexOf(payload) > -1) {

            let index = state.unanswered[Subject.state.selected_subject].indexOf(payload)

            state.unanswered[Subject.state.selected_subject].splice(index, 1);
        }
    },
    setUnAnsweredFromStorage(state, payload){

        for(let i=0; i<payload.length; i++) {

            if (payload[i].answered === -1) {

                if (state.unanswered[payload[i].subject_id] &&
                    state.unanswered[payload[i].subject_id].indexOf(payload[i].question_no) === -1) {

                    state.unanswered[payload[i].subject_id].push(payload[i].question_no)

                }
                else if (state.unanswered[payload[i].subject_id] &&
                    state.unanswered[payload[i].subject_id].indexOf(payload[i].question_no) > -1) {

                    return

                }
                else {

                    Vue.set(state.unanswered, payload[i].subject_id, [payload[i].question_no])

                }
            }
        }
    }
}

const actions = {
}

const getters = {
    getUnAnsweredQuestions(state){
        if(state.unanswered[Subject.state.selected_subject]) {
            return state.unanswered[Subject.state.selected_subject]
        }
        else {
            return []
        }
    },
    getUnAnsweredQuestionsCount(state){
        if(state.unanswered[Subject.state.selected_subject]) {
            return state.unanswered[Subject.state.selected_subject].length
        }
        else {
            return 0
        }
    },
    getAllUnAnsweredQuestions(state){
        return state.unanswered
    },
    getAllUnAnsweredQuestionsCount(state){
        let unansweredLength = 0
        for(let key in state.unanswered){
            unansweredLength += state.unanswered[key].length;
        }
        return unansweredLength
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
