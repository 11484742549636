<template>
    <div v-html="essay" style="padding: 10px"
         :style="{fontSize: fontSize + 'px'}"></div>
</template>
<script>
    export default {
        computed: {
            essay(){
                return this.convertText(this.$store.getters.getQuestionEssay)
            },
            fontSize(){
                return this.$store.getters.getfontSize;
            }
        }
    }
</script>