
const state = {
    duration    : 0,
    currentTime : 0,
    timer       : null
}

const mutations = {
    setExamDuration(state, payload){
        state.duration = parseInt(payload.duration)
        state.currentTime = parseInt(payload.duration) * 60
    },
    changeTime(state){
        state.currentTime--
    },
    StopClock(state){
        clearInterval(state.timer)
    },
    setTimerReference(state, payload){
        state.timer = payload
    }
}

const actions = {
    Clock({commit, state}){

        if(state.timer === null) {

            let timer = setInterval(function () {
                if (state.currentTime <= 0) {
                    commit('StopClock')
                } else {
                    commit('changeTime')
                }
            }, 1000);

            commit('setTimerReference', timer)

        }

    }
}

const getters = {
    getCurrentTime(state){
        return state.currentTime
    },
    getExamDuration(state){
        return state.duration
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
