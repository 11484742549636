<template>
    <div>
        <v-radio-group v-model="response">
            <div v-for="option in options" :key="option.value" style="padding: 5px">
                <v-radio :value='option.value'>
                    <template slot="label">
                        {{ convertText(option.text) }}
                    </template>
                </v-radio>
            </div>
        </v-radio-group>
    </div>
</template>
<script>
    import { UiRadio } from 'keen-ui'
    import QuestionResponse from '../../utils/QuestionResponse'

    export default {
        components: {
            appRadio: UiRadio
        },
        computed: {
            options(){
                return this.$store.getters.getOptions.filter(x => x.text && x.text.trim() != '')
            },
            response: {
                get(){
                    return this.$store.getters.getScResponse
                },
                set(value){
                    this.$store.commit('setScResponse', value)
                    QuestionResponse.markQuestionStatus()
                }
            }
        },
        methods:{
            SetAnswer(){
                var item = this.options[Math.floor(Math.random() * this.options.length)];
                this.response = item.value
            }
        }
    }
</script>