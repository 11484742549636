<template>
    <div>

        <v-layout row wrap>
            <v-flex lg4 md5 pa-2></v-flex>
            <v-flex lg4 md5 pa-2></v-flex>
            <v-flex lg4 md4 pa-2 style="padding:0px 35px !important">
                <v-layout wrap row>
                    <v-flex v-for="m in length_a" :key="m">
                        <b v-html="String.fromCharCode(64 + m)"></b>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout row wrap v-for="(option, i) in count" :key="i">
            <v-flex lg4 md4 sm12 xs12 pa-2 style="border: 1px solid #d2d1d1; text-align: center; padding: 30px 0px !important;">
                <div v-if="i < length_a">
                    <b v-html="String.fromCharCode(65 + i)"></b>
                    <span v-html="convertText(options.section_a[i].text)" style="margin-left: 5px"></span>
                </div>
            </v-flex>
            <v-flex lg4 md4 sm12 xs12 pa-2 style=" border: 1px solid #d2d1d1; text-align: center; padding: 30px 0px !important; overflow: auto !important;">

                <div v-if="i < length_b">
                    <b v-html="String.fromCharCode(80 + i)"></b>
                    <span v-html="convertText(options.section_b[i].text)" style="margin-left: 5px"></span>
                </div>

            </v-flex>
            <v-flex lg4 md4 sm12 xs12 pa-2 style="border: 1px solid #d2d1d1; text-align: center; padding: 30px 35px !important;">
                <v-layout wrap row>
                    <v-flex v-for="m in length_a" :key="m">
                        <v-checkbox v-model="response" color="primary"
                                    :value="String.fromCharCode(96 + m) + '-' + String.fromCharCode(112 + i)"></v-checkbox>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import QuestionResponse from '../../utils/QuestionResponse'
    import ConvertQuestion from '../../utils/ConvertQuestionText'

    export default {
        computed: {
            options() {
                return this.$store.getters.getOptions
            },
            response: {
                get(){
                    return this.$store.getters.getMatResponse
                },
                set(value){
                    this.$store.commit('setMatResponse', value)
                    QuestionResponse.markQuestionStatus()
                }
            },
            length_a(){
                return this.$store.getters.getMatSectionALength
            },
            length_b(){
                return this.$store.getters.getMatSectionBLength
            },
            count(){
                return this.length_a > this.length_b ? this.length_a : this.length_b
            }
        },
        methods:{
            SetAnswer(){
                
            }
        }
    }
</script>