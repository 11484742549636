<template>
    <section style="text-align:center;">
        <video class="normal stream1"></video>
        <canvas class="capture1" style="display: none;" width="320" height="240"></canvas>
    </section>
</template>
<script>

let cameraStream;
let interval_lock = null;
import axios   from 'axios';

export default {
    data(){
        return {
            
        }
    },
    computed:{
        User(){
            return this.$store.getters.GetUserInfo
        },
        Mock(){
            return this.$store.getters.GetMockExam
        }
    },
    methods:{
        take_snapshot() {
            let _this = this
            var capture = document.getElementsByClassName("capture1")[0];
            var stream = document.getElementsByClassName("stream1")[0];
            if( null != cameraStream ) {
                var ctx = capture.getContext( '2d' );
                var img = new Image();
                ctx.drawImage( stream, 0, 0, capture.width, capture.height );
                // capture.toDataURL("image/png") ? _this.UploadImage(capture.toDataURL("image/png")) : {};
            }
        },
        UploadImage(image){
            
            // if(!this.$store.getters.GetUpload){

            //     let User = this.$store.getters.GetUserInfo
            //     axios.post(`${this.$store.getters.GetServer}/student/upload_image`,{ "User_Image": image , ...User , "Mock" : this.Mock })
            //         .then(response =>{
                        
            //             if(response && response['data'] && response['data']['response'] && response['data']['response']['images'] && 
            //                 response['data']['response']['images'].length > 0){
            //                 this.$Socket.SendScreenShot({ ...User , Image : response['data']['response']['images'][response['data']['response']['images'].length - 1]})
            //             }
            //         })
            //         .catch(error => {
                        
            //             console.log(error);
            //         })
            // }
        },
        Start_Streaming(){

            var stream = document.getElementsByClassName("stream1")[0];
            this.$eventHub.$emit("WEBCAM_STATUS",true);
            navigator.mediaDevices.getUserMedia( { video: { width: { min: 169, max: 169 } , height: { min: 108 , max: 108 } } } )
                .then(( mediaStream ) => {
                    
                    cameraStream = mediaStream;
                    stream.srcObject = mediaStream;
                    return stream.play();
                })
                .then(_ => {
                    
                    console.log(_)
                })
                .catch(( err ) => {

                    console.log( "Unable to access camera: " + err );
                });
        },
        SetDisableCam(){

            var stream = document.getElementsByClassName("stream1")[0];

            if( null != cameraStream ) {

                var track = cameraStream.getTracks()[ 0 ];
                track.stop();
                stream.load();
                cameraStream = null;
            }
    
        }
    },
    mounted(){

        this.Start_Streaming()
    },
    destroyed() {
            clearInterval(interval_lock);
    },
    created() {
        let _this = this;
        interval_lock = setInterval(() => {
            let User_Info = this.$store.getters.GetUserInfo
            User_Info && User_Info['email'] && User_Info['id'] ? _this.take_snapshot() : ""
        }, 6000);
    },
}
</script>
<style>
.normal {
    height: 108px !important;
    width: 169px !important;
    transform: scaleX(-1.12) !important;
    padding-top: 5px;
}
.system {
    transform: scaleX(-1) !important;
}
.dispay_none{
    display: none;
}
</style>