<template>
    <section>
        <v-app-bar app color="primary" dark :elevation="2">
            <!-- <div class="d-flex align-center">
            </div> -->          
            <v-toolbar-title class="d-flex align-center justify-start">
                <img alt="Examin" class="shrink mr-5" :src="Logo" style="height: 45px;max-width: 160px;object-fit: scale-down;" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-flex style="text-align: right;margin: 0px 14px;">
                <app-server-time></app-server-time>
                <v-btn v-if="status && $route.name === 'instruction'" style="margin: 15px;background-color: #FFFFFF;color: #272727;" @click.native="StartExam()">Start Exam</v-btn>
                <v-icon v-if="connection">signal_wifi_4_bar</v-icon>
                <v-icon v-else>signal_wifi_off</v-icon>
            </v-flex>
            </v-app-bar>
        <router-view></router-view>    
    </section>
</template>
<script>
import appServerTime from '@/components/ServerTime'
import appLogo from '@/assets/logo.png'
export default {
    components: {
      appServerTime
    },
    computed: {
        connection(){
            return this.$store.getters.GetConnectionStatus;
        },
        Logo(){
            return this.$store.getters.GetInstituteDetails && this.$store.getters.GetInstituteDetails.image ? `https://examin.co.in/${this.$store.getters.GetInstituteDetails.image}` : appLogo
        },
        status(){
            return this.$store.getters.getExamStatus
        },
        instituteDetails(){
            return this.$store.getters.GetInstituteDetails
        }
    },
    methods:{
        StartExam(){
            this.$router.push('/exam');
            this.$Socket.WriteLOGs(`${this.getUserDetails['first_name']} Exam Started at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
        }
    }
}
</script>
<style scoped>

</style>