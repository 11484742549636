import Subject from './Subjects'
import Vue     from 'vue';


const state = {
    subject_wise_question_count: {},
    subject_wise_questions: {},
    subject_wise_selected_question: {},
    total_QuestionCount:0
}

const mutations = {
    setQuestions(state, payload) {

        for (let i=0; i<payload.data.length; i++) {

            let data = payload.data[i];
            Vue.set(state.subject_wise_question_count, data.id, parseInt(data.question_count))
            Vue.set(state.subject_wise_questions, data.id, data.questions)
            Vue.set(state.subject_wise_selected_question, data.id, 1)
            state.total_QuestionCount += parseInt(data.question_count);
        }
    },
    setQuestionsNumber(state, payload) {

        Vue.set(state.subject_wise_selected_question, Subject.state.selected_subject, payload)
    }
}

const actions = {
}

const getters = {
    getSubjectWiseSelectedQuestion(state){
        return state.subject_wise_selected_question[Subject.state.selected_subject]
    },
    getQuestionCount(state) {
        return state.subject_wise_question_count[Subject.state.selected_subject]
    },
    getCurrentQuestionsNo(state){
        return state.subject_wise_selected_question[Subject.state.selected_subject]
    },
    getNextQuestion(state){

        return state.subject_wise_questions[Subject.state.selected_subject][state.subject_wise_selected_question[Subject.state.selected_subject]-1]

    },
    getSelectedSubjectQuestions(state){

        return state.subject_wise_questions[Subject.state.selected_subject]
    },
    getSubjectWiseQuestionCount(state){
        return state.subject_wise_question_count
    },
    getAllQuestionCount(state){
        return state.total_QuestionCount
    },
    getAllQuestions(state){
        return state.subject_wise_questions
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
