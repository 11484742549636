<template>
    
</template>
<script>
import Time  from '../utils/Time';
import io from 'socket.io-client';
import Socket from '../socket/Socket';

export default {
    data(){
        return {

        }
    },
    computed:{
        GetUserInfo(){

            return { ...this.$store.getters.GetUserInfo }
        }
    },
    methods:{
        Login(){

            let data = {'identifier' : this.$route.params.uid }
            this.$http.post(`${this.$store.getters.GetServer}/student/student_login`,data)
                .then(response =>{  
                    
                    if(response && response['data'] && response['data']['response']){

                        this.$store.commit('SetUserInfo',response['data']['response']);
                        this.$store.commit('setExamDetails', response['data']['response']['datetime'])
                        Socket.close();
                        let socket = io(`${this.$store.getters.GetServer}/student` , { query: `user_id=${this.GetUserInfo['id']}&first_name=${this.GetUserInfo['first_name']}&system_no=${Math.floor(100 + Math.random() * 900)}`})
                        Socket.init(socket)
                        this.GetNextInformation();

                    }else if(response && response['data'] && response['data']['error']){

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${response['data']['error']['message'] ? response['data']['error']['message'] : response['data']['error'] }`,"Color":"red","Close": false })
                    }

                })
                .catch(error =>{

                    this.$eventHub.$emit("LOADING",'false');
                    error && error.message === "Network Error" ? this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": 'Please Check Internet Connection .',"Color":"red","Close": false }) : this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${error}`,"Color":"red","Close": false });  
                    
                })
        },
        async GetNextInformation(){

                let data = { center_id : this.GetUserInfo['center_id']  , first_name : this.GetUserInfo['first_name'], datetime : this.GetUserInfo['datetime'] }
                data['system_details'] = { ...this.$store.getters.GetSystemDetails , ...this.$store.getters.GetSystemInfo , type : process.env.VUE_APP_BUILD_TYPE }
                this.$http.post(`${this.$store.getters.GetServer}/student/exam_info`,data)
                        .then(response =>{

                            if(response && response['data'] && response['data']['response']){

                                this.$store.commit('SetLoginTimeValidation',response['data']['response']['time_validation']);
                                this.$store.commit('SetCalculator',response['data']['response']['calculator_enable']);
                                this.$store.commit('SetCalculatorType',response['data']['response']['simple_calculator']);
                                this.$store.commit('setResumeExam',response['data']['response']['resume_exam']);
                                Time.calculateExamDuration(response['data']['response'])
                                this.ShipExamInformation();

                            }else{


                            }

                        })
                        .catch(error => {

                            console.log(error);
                        })
                
        },
        async ShipExamInformation(){

            const response = await this.$http({ 
                    method: 'post',
                    url: `${this.$store.getters.GetServer}/student/ship_exam`,
                    data : { 'center_id' : this.GetUserInfo['center_id'] , 'first_name' : this.GetUserInfo['first_name'], 'datetime' : this.GetUserInfo['datetime'] , "exam_id" : this.GetUserInfo['exam_id'] ,"set_id" : 1 },
                    onDownloadProgress: (progressEvent) => {
                        
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length');
                        if (totalLength !== null) {
                            this.Download_Percentage = Math.round( (progressEvent.loaded * 100) / totalLength )
                            console.log(this.Download_Percentage)
                        }

                    }
                })
            if(response && response['data']){

                const stream = response.data;
                this.$store.dispatch('getExam', stream);
                this.$router.push('/instruction')
                
            }else{

            }
            this.$eventHub.$emit("LOADING",'false');
        },
    },
    mounted(){

        this.$route.params && this.$route.params.uid ? this.Login() : {}
    }
} 
</script>
<style scoped>

</style>