import Vue from 'vue'

const state = {
    selected_subject: null,
    subject_names: {},
    subject_ids: [],
}

const mutations = {
    setSubjects(state, payload) {
        for (let i=0; i<payload.data.length; i++) {

            let data = payload.data[i];
            state.subject_ids.push(data.id)
            Vue.set(state.subject_names, data.id, data.name)

        }
        state.selected_subject = state.subject_ids[0]
    },
    setSelected(state, payload) {
        state.selected_subject = payload
    }
}

const actions = {
}

const getters = {
    getSubjecNames(state){
        return state.subject_names;
    },
    getSelectedSubject(state){
        return state.selected_subject
    },
    getSubjectIds(state) {
        return state.subject_ids
    },
    getSelectedSubjectName(state){
        return state.subject_names[state.selected_subject]
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
