<template>
    <span>
        <v-btn small fab :color="btnState" @click="btnState = n" class="btnCls">{{ n }}</v-btn>
    </span>
    
</template>
<script>
    import QuestionControl   from '../../utils/QuestionControl'
    import DatastoreResponse from '../../datastore/Response'
    import QuestionTime      from '../../utils/Time'
    import Calculate         from '../../utils/Calculate'


    export default {
      props: ['n'],
      computed:{
        btnState: {
            get() {
                return this.$store.getters.getMarkedQuestions.indexOf(this.n) > -1 ? 'warning' :
                this.$store.getters.getAnsweredQuestions.indexOf(this.n) > -1 ? 'success' :
                this.$store.getters.getUnAnsweredQuestions.indexOf(this.n) > -1 ? 'error' :
                this.n === this.$store.getters.getCurrentQuestionsNo ? 'info' :
                    ''
            },
            set(value) {
                
                QuestionTime.saveTimeTaken()
                Calculate.calculate()
                DatastoreResponse.store()
                this.$store.commit('setQuestionsNumber', value)
                QuestionControl.change()
            }
        }
      }
    }
</script>
<style scoped>
    .btnCls{
        min-width: 0;
        height: 45px !important;
        width: 45px !important;
        padding: 0;
        margin: 3px 2% !important;
    }
    .warning{
        min-width: 0;
        height: 45px;
        width: 45px;
        padding: 0;
    }
    .success{
        min-width: 0;
        height: 45px;
        width: 45px;
        padding: 0;
    }
    .error{
        min-width: 0;
        height: 45px;
        width: 45px;
        padding: 0;
    }
    .info{
        min-width: 0;
        height: 45px;
        width: 45px;
        padding: 0;
    }
</style>
