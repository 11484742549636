<template>
    <div>
        <v-flex style="padding: 2px;" ma-2>
            <v-btn block color="red" dark @click.native="submitExam = true">Submit</v-btn>
        </v-flex>
        <v-layout row ma-1>
            <v-flex>
                <div>
                    <v-btn class="makeSmall" color="success" fab small dark>
                    </v-btn>
                    <span class="fontMakeSmall">Answered</span>
                </div>
            </v-flex>
            <v-flex>
                <div>
                    <v-btn class="makeSmall" color="error" fab small dark>
                    </v-btn>
                    <span class="fontMakeSmall">Not Answered</span>
                </div>
            </v-flex>
            <v-flex>
                <div>
                    <v-btn class="makeSmall" color="warning" fab small dark>
                    </v-btn>
                    <span class="fontMakeSmall">Marked</span>
                </div>
            </v-flex>
            <v-flex>
                <div>
                    <v-btn class="makeSmall borderCls" color="white" fab small dark>
                    </v-btn>
                    <span class="fontMakeSmall">Not Visited</span>
                </div>
            </v-flex>
        </v-layout>

        <v-dialog v-model="submitExam" persistent scrollable max-width="50%">
            <v-card>
                <v-card-title class="headline">Finish and Submit Exam</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <h2>Do you really want to submit and finish the exam?</h2>
                    <h5>To submit exam click on submit button</h5>
                    <h5>To cancel, click on cancel button</h5>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.native="submitExam = false">Cancel</v-btn>
                    <v-btn color="primary" text @click.native="finish(false)">Submit & Finish Exam</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        

        <v-dialog v-model="submit_dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
             <v-card tile>
                <v-card-text style="margin-top: 16%;">
                    <v-stepper alt-labels>
                        <v-stepper-header>
                            <template v-for="(submits,index) in submit_steps" >
                                    <v-stepper-step :step="index + 1" :complete='submits.status' :key="submits.type + '_step'">
                                        {{ submits.text }}
                                    </v-stepper-step>
                                <v-divider :key="submits.type + '_divider'" v-if="index < submit_steps.length - 1" :style="{ 'border-color': submits.status ? '#4169E1' : 'light-grey'}"></v-divider>
                            </template>
                        </v-stepper-header>
                        <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                    </v-stepper>
                </v-card-text>
             </v-card>
        </v-dialog>
        <v-dialog v-model="submit_responses" persistent fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
             <v-card tile>
                <v-card-text style="margin-top: 16%;">
                    <v-flex xs6 style="margin:auto;text-align: center;">
                        <v-file-input style="margin-top:5px;" v-model="upload_file" color="primary" label="Upload Response File"
                                        placeholder="Select Downloaded File" prepend-icon="mdi-paperclip" outlined show-size>
                            <template v-slot:selection="{ text }">
                                <v-chip color="primary" dark label small>
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                        <v-btn color='primary' @click="UploadResponses">Upload Response</v-btn>
                    </v-flex>
                </v-card-text>
             </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import FinishExam from '../../utils/FinishExam';
    import DatastoreResponse from '../../datastore/Response';
    import crypto from 'crypto';

    export default {
        data(){
            return {

                ENCRYPTION_KEY : process.env.VUE_APP_ENCRYPTED_PASS,
                IV_LENGTH      : 16,
                submit_dialog  : false,
                submit_steps   : [
                    {
                        'type'   : 'fetch_responses',
                        'text'   : 'Analysing Responses',
                        'status' : false,
                    },
                    {
                        'type'   : 'update_responses',
                        'text'   : 'Updating Response',
                        'status' : false,
                    },
                     {
                        'type'   : 'fetch_subject_wise',
                        'text'   : 'Analyising Subject Wise Response',
                        'status' : false,
                    },
                    {
                        'type'   : 'update_subject_wise',
                        'text'   : 'Updating Subject Wise Response',
                        'status' : false,
                    },
                    {
                        'type'   : 'final_update',
                        'text'   : 'Final Update',
                        'status' : false,
                    }
                ],
                submit_responses : false,
                upload_file      : [],
                submitExam    : false
            }
        },
        computed:{
            student(){
                return this.$store.getters.GetUserInfo
            },
            submit_status(){
                return this.$store.getters.GetSubmit
            },
            time(){
                return this.$store.getters.getCurrentTime
            },
            mockstatus(){
                return this.$store.getters.GetMockExam
            }
        },
        watch:{
            submit_status(value){

                let index = this.submit_steps.findIndex(x => x.type == value.type)
                value.status && index > -1 ? this.submit_steps[index]['status'] = true  : this.UpdateFailed(value);

            },
            time(value){
                if(value <= 0) {
                    this.finish(false)
                }
            },
            mockstatus(){
                if(!this.mockstatus){

                    this.finish(false)
                }
            }
        },
        methods: {
            async finish(manual){
                
                this.submit_dialog = true
                // await this.DownloadJSON()
                FinishExam.finish(manual)
                    .then(response =>{

                        this.$Socket.WriteLOGs(`${this.student['first_name']} Successfully Submitted at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);
                        this.$store.commit('SetSubmit',{ type:"final_update" , 'status' : true });
                        this.$store.commit('SetDisplayedResult',response['result'])
                        this.$router.push('/result');
                        this.submit_dialog = false
                        
                    })
                    .catch(error =>{

                        this.submit_dialog = false
                        this.$Socket.WriteLOGs(`${this.student['first_name']} Failed To Submit at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);
                        this.$store.commit('SetSubmit',{ type:"final_update" , 'status' : false })
        
                    })
            },
            UpdateFailed(submit_status){
                
                if(submit_status.type == 'fetch_responses' || submit_status.type == 'update_responses'){

                    FinishExam.SaveReponseToDB()
                        .then(response =>{

                            response ? this.$router.push('/result') : this.submit_dialog = false ; this.submit_responses = true;;
                        })
                        .catch(error =>{  

                            this.submit_dialog = false;
                            this.submit_responses = true;
                        })

                }else{

                }
            },
            DownloadJSON(){

                DatastoreResponse.get()
                    .then(response =>{
                        
                        this.$store.commit('setFinalQuestions')
                        this.$store.commit('setFinalAnswers', response)
                        let iv = crypto.randomBytes(this.IV_LENGTH);
                        let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(this.ENCRYPTION_KEY), iv);
                        let encrypted = cipher.update(JSON.stringify(response));
                        encrypted = Buffer.concat([encrypted, cipher.final()]);
                        this.DownloadFile(this.student['first_name'],iv.toString('hex') + ':' + encrypted.toString('hex'))
    
                    })
                    .catch(error =>{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${error}`,"Color":"red","Close": false });
                    })
            },
            DownloadFile(filename, text) {

                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                this.$Socket.WriteLOGs(`${this.student['first_name']} Downloaded Response JSON at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);

            },
            UploadResponses(){

                if(this.upload_file){

                    this.$eventHub.$emit("LOADING",'true')
                    const fd = new FormData();
                    fd.append('hash_file', this.upload_file,this.upload_file.name);
                    this.$http.post(`${this.$store.getters.GetServer}/student/read_responses`,fd)
                        .then(response =>{
                            
                            this.$Socket.WriteLOGs(`${this.student['first_name']} Updated Responses at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);
                            this.submit_responses = false
                            this.$eventHub.$emit("LOADING",'false');
                            this.$router.push('/result')
                                
                        })
                        .catch(error =>{

                            this.$Socket.WriteLOGs(`${this.student['first_name']} Failed To Update Response at ${new Date().toDateString()} , ${new Date().toLocaleTimeString()} `);
                            this.$eventHub.$emit("LOADING",'false')
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Error While Uploading File","Color":"red","Close": false });
                        })

                }else{
                     
                     this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Select The Downloaded File`,"Color":"red","Close": false })
                }
            }
        }
    }
</script>
<style>
    .makeSmall {
        height: 10px !important;
        width: 10px !important;
    }
    .fontMakeSmall {
        font-size: 12px;
         margin: 0px 10px;
    }
    .theme--light.v-stepper .v-stepper__label {
        text-align: center;
    }
    .borderCls{
        border: 1px solid #d3d3d347 !important;
    }
</style>