import Vue from 'vue'


Vue.mixin({
    methods: {
        convertText(value){
            let txt = document.createElement("textarea");
            txt.innerHTML = value;
            return txt.value;
        }
    }
})
