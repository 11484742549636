import store from '../index'

const state = {
    datetime: null,
    timeToStart: 0,
}

const mutations = {
    setExamDetails(state, payload){
        state.datetime = new Date(payload).getTime()
    },
    timeToStart(state, payload){
        state.timeToStart = payload
    },
}

const actions = {
    CheckExamStatus({commit, state}){
        let time = store.getters.GetUpdatedServerTime
        time >= state.datetime ?  commit('startExam') : commit('timeToStart', state.datetime - time)
    }
}

const getters = {
    getTimeToStart(state){
        return state.timeToStart
    },
    getExamDateTime(state){
        return state.datetime
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
