import Vue from 'vue'
import ActiveQuestion from './ActiveQuestions'
import QuestionControl from '../../utils/QuestionControl'

const state = {
    response: {},
    sync_response: null
}

const mutations = {
    setStudentResponse(state, payload){
        Vue.set(state.response, ActiveQuestion.state.question_id, payload)
    },
    clearStudentResponse(state, payload){
        delete state.response[ActiveQuestion.state.question_id]
    },
    setResponseFromStorage(state, payload){

        for(let i=0; i<payload.length; i++) {
            Vue.set(state.response, payload[i].question_id, payload[i].response)
        }

        QuestionControl.manageResponse()
    },
    setResponseForSync(state, payload){
        state.sync_response = payload
    },
    clearSyncResponse(state, payload){
        state.sync_response = payload
    }
}

const actions = {
}

const getters = {
    getStudentResponse(state) {
      return  state.response[ActiveQuestion.state.question_id]
    },
    getResponseForSync(state){
        return state.sync_response
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
