<template>
    <section style="display: flex;height: 100vh;">
        <v-flex xs6 ma-2>
            <img :src="image" class="ImageClass"/>
        </v-flex>
        <v-flex xs6 ma-2 class="clsForFinal">
            <v-flex class="clsFont">Great ,  Your Examination Submitted Successfully On Time.</v-flex>
            <v-flex class="clsFont" mt-4> Thank You For Attempting Online Examination. </v-flex>
            <v-flex mt-4>
                <img v-if="student.verified_image" :src='student.verified_image' style="width: 200px;height: 200px;border-radius: 5px;box-shadow: 0px 0px 5px 2px #00000026;" />
            </v-flex>
            <v-flex style="display:flex;font-size: 14px;">
                <v-flex class="clsForLeft">
                    <v-flex><b> Name : </b> </v-flex>
                    <v-flex><b> Roll Number : </b> </v-flex>
                    <v-flex><b> Exam Duration : </b> </v-flex>
                    <v-flex><b> Login Attempts : </b> </v-flex>
                    <v-flex><b> LoggedIn Time : </b> </v-flex>
                </v-flex>
                <v-flex class="clsForRight">
                    <v-flex>{{ student.last_name }} </v-flex>
                    <v-flex>{{ student.first_name }} </v-flex>
                    <v-flex>{{ Math.ceil(student.exam_duration) }} Minutes</v-flex>
                    <v-flex>{{ student.login_attempts }} </v-flex>
                    <v-flex>{{ new Date(student.login_time).toLocaleTimeString() }}</v-flex>
                </v-flex>
            </v-flex>
            <v-flex mt-3>
                <v-btn small @click="CloseApplication" color='primary'>Finsh & Close Exam Panel</v-btn>
            </v-flex>
            <v-flex class="clsFont" mt-4>Best Wishes For Your Result and Awesome Future.</v-flex>
        </v-flex>
    </section>
</template>
<script>
import axios from 'axios';

export default {
    data(){
        return {
            student : {},
            w: process.env.IS_ELECTRON ? require('electron').remote.getCurrentWindow() : {},
        }
    },
    computed:{
        image(){
            return require('../assets/Asset1.png')
        }
    },
    methods:{
        CloseApplication(){

            this.$Socket.ResetDBShutDown(true)
            window.location.reload();
            this.$router.push('/login')
        }
    },
    mounted(){  
        
        let User = this.$store.getters.GetUserInfo
        axios.post(`${this.$store.getters.GetServer}/student/get_student_info`, User )
                .then(response =>{

                    this.student = response && response['data'] && response['data']['response'] ? response['data']['response'] : {}  
                    console.log(this.student)
                    this.$eventHub.$emit("LOADING",'false');
                })
                .catch(error => {

                    console.log(error);
                })
    }    
}
</script>
<style>
.ImageClass{
    width: 100%;
    height: 80%;
    margin-top: 4% !important;
}
.clsForFinal{
    /* width: 35%;
    margin: auto; */
    /* height: 100vh; */
    text-align: center;
    margin-top: 4% !important;
    padding-top: 12%;
}
.clsForLeft{
    text-align: right;
    margin: 0px 10px;
    width: 50%;

}
.clsForRight{
    text-align: left;
    margin: 0px 10px;
    width: 50%;
}
.clsFont{
    font-family: 'Pacifico';
    font-size: 20px;
    color: #1976d2 !important;
}
</style>