<template>
    <div class="paper-sectionDelault section">
        <v-tooltip color='success' bottom v-for="(subject, key,) in subjects" v-bind:key="key">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text
                        :outlined="selected == key"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="selected = key" @mouseover="SubjectAnalysis(subject)">
                            {{ subject }}
                    </v-btn>
                </template>
                <div>Total Questions : {{ subject_analysis.total_questions }}</div>
                <div>Answered : {{ subject_analysis.answered }}</div>
                <div>Marked : {{ subject_analysis.marked }}</div>
                <div>Not Answered : {{ subject_analysis.unanswered + subject_analysis.not_visited }}</div>  
        </v-tooltip>
    </div>
</template>
<script>
    import QuestionControl from '../../utils/QuestionControl'
    import DatastoreResponse from '../../datastore/Response'
    import QuestionTime from '../../utils/Time'
    import Calculate from '../../utils/Calculate'
    export default {
        data(){
            return {
                subject_analysis : {
                    "subject"     : 0 ,
                    "answered"    : 0 ,  
                    "marked"      : 0 , 
                    "unanswered"  : 0 ,  
                    "not_visited" : 0 ,
                    "total_questions" : 0
                }
            }
        },
        computed: {
            subjects () {
                return this.$store.getters.getSubjecNames
            },
            answered(){
                return this.$store.getters.getAllAnsweredQuestions;
            },
            marked(){
                return this.$store.getters.getAllMarkedQuestions;
            },
            unanswered(){
                return this.$store.getters.getAllUnAnsweredQuestions;      
            },
            questioncount(){
                return this.$store.getters.getSubjectWiseQuestionCount;
            },
            selected: {
                get(){
                    return this.$store.getters.getSelectedSubject
                },
                set(value){
                    QuestionTime.saveTimeTaken()
                    Calculate.calculate()
                    DatastoreResponse.store()
                    this.$store.commit('setSelected', value)
                    QuestionControl.change()
                }
            }
        },
        methods: {
            SubjectAnalysis(subject){

                let subject_id = Object.keys(this.subjects).find(key => this.subjects[key] === subject);
                let answered    = this.answered[subject_id] ? this.answered[subject_id].length : 0
                let total_count = this.questioncount[subject_id] ? this.questioncount[subject_id] : 0
                let unanswered  = this.unanswered[subject_id] ? this.unanswered[subject_id].length : 0
                let marked      = this.marked[subject_id] ? this.marked[subject_id].length : 0
                let not_visited = total_count ? total_count - (answered + unanswered + marked ) : 0
                this.subject_analysis =  { 
                          "subject" : subject , "answered" : answered ,  "marked" : marked , 
                          "unanswered" : unanswered ,  "total_questions" : total_count ,"not_visited" : not_visited  }
            }
        }
    }
</script>
<style scoped>

.paper-section{
    display: flex;
    width: 100%;
    border-top: solid 1px rgba(148, 148, 148, 0.28);
    position: relative;
    color: rgb(25, 118, 210);
    background: white;
    padding-left: 15px;
    
}
.paper-sectionDelault{
    display: flex;
    width: 100%;
    position: relative;
    color: rgb(25, 118, 210);
    margin: 8px 14px;
    background: white;  
    overflow: auto;
}
.pbutton{
    width: auto;
    padding: 5px 8px;
    height: auto;
    border-right: solid 1px rgb(148, 148, 148);
    color: black;
    background-color: rgb(255, 255, 255)
}
.dropDown{
    position: relative;
    float: right;
    margin-left: 5px;
}
.dropDownlist{
    position: absolute;
    width: 260px;
    color: rgb(0, 0, 0);
    background-color: rgb(203, 240, 240);
    z-index: 2;
    left: -30px;
    padding: 0px 5px;
    display: none;
}
.dropDown:hover .dropDownlist{
    display: block;
}
.success{
        /* background-image: url('../../assets/ans.png') !important; */
        background-color: transparent !important;
        border: none !important;
        background-repeat: no-repeat;
        background-size: 39px;
        background-position: 11px 0px;
        text-align: center;
        color: white;
    }
    .error{
        background-image: url('../../assets/nans.png') !important;
        background-color: transparent !important;
        border: none !important;
        background-repeat: no-repeat;
        background-size: 39px;
        background-position: 11px -10px;
        text-align: center;
        color: white;
    }
    .review{
        background-color: #6f4e8f !important;
        color: white;
    }
</style>
