<template>
<div>
<v-layout  justify-space-between>
    <div>
        <span style="font-size: 17px; float:right; padding:3px 20px;"> {{ remainingTime | timer}}</span>
    </div>
</v-layout>  
</div>
</template>
<script>
    export default {
        computed: {
            remainingTime(){
                return this.$store.getters.getCurrentTime
            }
        },
        filters: {
            timer(time){
                let minutes = parseInt(Math.trunc(time/60));
                let seconds = parseInt(Math.trunc(time%60));
                minutes = minutes<10?'0'+minutes:minutes;
                seconds = seconds<10?'0'+seconds:seconds
                return minutes + " : " + seconds;
            }
        }
    }
</script>
<style scoped>

</style>
