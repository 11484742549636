<template>
    <span style="margin: 10px;">{{ FormatServerTime }}</span>
</template>
<script>

import Moment from 'moment';
export default {
    data(){
        return {

        }
    },
    computed:{
        FormatServerTime(){

            return this.$store.getters.GetUpdatedServerTime ? Moment(new Date(this.$store.getters.GetUpdatedServerTime)).format('hh:mm:ss') : ""
        
        }
    }
}
</script>
<style scoped>

</style>