const state = {

    verified_image  : '',
    user_info       : {},
    time_validation : false,
    calculator      : false,
    calculator_type : false,

  }
  
  const mutations = {

    SetVerifiedImage(state,payload){
        state.verified_image = payload
    },
    SetUserInfo(state,payload){
        state.user_info = payload
    },
    SetLoginTimeValidation(state, payload){
      state.time_validation = payload
    },
    SetCalculator(state, payload){
        state.calculator = payload
    },
    SetCalculatorType(state, payload){
        state.calculator_type = payload
    }

  }
  
  const actions = {
      
  }
  
  const getters = {

    GetVerifiedImage(state){
        return state.verified_image;
    },
    GetUserInfo(state){
        return state.user_info
    },
    GetLoginTimeValidation(state){
      return state.time_validation
    },
    GetCalculator(state){
        return state.calculator
    },
    GetCalculatorType(state){
        return state.calculator_type
    },

  }
  
  export default {
    state,
    mutations,
    actions,
    getters
  }
  