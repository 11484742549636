<template>
      <v-flex style="text-align: center;margin: 0px 12px;border: 1px solid #d3d3d33b;">
        <div id="content1" style="width: 100%;height: 34px;position: relative;margin: auto;background: white;"></div>
      </v-flex>
</template>
<script>

import $ from "jquery";
var audioContext = null;
let analyser = null;
let dataArray = null;
let bufferLength = 0;

export default {

  data(){
    return {

    }
  },
  methods:{
      StartAudio(){

        let _this = this;
        audioContext.resume().then(() => {
            console.log("Audio Context Started")
        });

      },
      didntGetStream() {
        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Stream Generation Failed.","Color":"red","Close": false });
      },
      gotStream(Stream){

            var context = new AudioContext();
            var src = context.createMediaStreamSource(Stream);
            analyser = context.createAnalyser();
            
            var gainNode  = context.createGain();
            gainNode.gain.value = 0;

            src.connect(analyser);
            // analyser.connect(context.destination);

            analyser.connect(gainNode);
            gainNode.connect(context.destination)
        
            analyser.fftSize = 32;
            analyser.minDecibels = -100;
            analyser.maxDecibels = 0;
        
            bufferLength = analyser.frequencyBinCount;    
            dataArray = new Uint8Array(bufferLength);

            this.renderFrame();
        },
        renderFrame(){

            var content = $('#content1');
            var barWidth = 100 / bufferLength;

            for(var i = 0; i < bufferLength; i++) {
                $('<div/>').css({
                    "position":"absolute",
                    "left": i * barWidth +'%',
                    "bottom":0,
                    "width": (barWidth - 0.1) + '%',
                    "float":'left'
                }).appendTo(content)
            }

            var bars = $('#content1 > div')
            let _this = this;
            setInterval(function(){
                analyser.getByteFrequencyData(dataArray);
                bars.each(function( index , bar ){
                    $(bar).css({
                        'height' : dataArray[index].map(0,255,0,$('#content1').height()) + 'px',
                        'background' : 'rgb(' + dataArray[index] + ',' + (255 - dataArray[index]) + ', 0 )'
                    })
                })

            },10)

        }
  },
  mounted(){

     Number.prototype.map = function(in_min,in_max,out_min,out_max) {
            return ( this - in_min ) * ( out_max - out_min ) / ( in_max - in_min ) + out_min
     }
    
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    
    audioContext = new AudioContext();

    try {
  
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        navigator.getUserMedia({ "audio": { "mandatory": { "googEchoCancellation": "false", "googAutoGainControl": "false",
                    "googNoiseSuppression": "false", "googHighpassFilter": "false" }, "optional": [] } }, this.gotStream, this.didntGetStream);
    } catch (e) {

        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": "Audio Stream Generation Failed.","Color":"red","Close": false });
    }

    this.StartAudio();
    
  }

}

</script>
<style scoped>
</style>
