import store from '../store'

export default {
    previous(){

        let currentQuestion = store.getters.getCurrentQuestionsNo
        currentQuestion === 1 ? this.changeSubject() : this.changeQuestion(currentQuestion-1);

    },
    changeSubject(){


        let subjectIds = store.getters.getSubjectIds
        let currentSubject = store.getters.getSelectedSubject
        let currentSubjectQuestionCount = store.getters.getQuestionCount

        if(subjectIds.indexOf(parseInt(currentSubject)) === 0) {

            store.commit('setSelected', subjectIds[subjectIds.length-1])
            this.changeQuestion(currentSubjectQuestionCount)

        }
        else {

            let currentSubjectQuestionCount = store.getters.getQuestionCount
            let index = subjectIds.indexOf(parseInt(currentSubject)) - 1
            store.commit('setSelected', subjectIds[index])
            this.changeQuestion(currentSubjectQuestionCount)
        }
    },
    changeQuestion(currentQuestion){

        store.commit('setQuestionsNumber', currentQuestion)
        
    }
}