<template>
    <v-flex id="question_info" style="display:flex;">
        <v-flex id="question_no">
            <h3>Question No: {{ currentQuestion }}</h3>
        </v-flex>
        <v-flex class="language" :class="{ padding_zero : $vuetify.breakpoint.xsOnly }">
                <v-btn v-show="$vuetify.breakpoint.mdAndUp" style="margin: 0px 10px;" small dark color="secondary" @click.native="instructions">Instruction</v-btn>
                <v-btn v-show="$vuetify.breakpoint.mdAndUp" style="margin: 0px 10px;" small dark color="secondary" @click.native="questions">Questions</v-btn>

                <v-btn v-show="!$vuetify.breakpoint.xsOnly"  fab dark small color="primary" class="makeSmall" @click="increaseFont">
                    <v-icon dark>add</v-icon>
                </v-btn>
                <v-btn v-show="!$vuetify.breakpoint.xsOnly" fab dark small color="primary" class="makeSmall2" @click="resetFont">
                    100
                </v-btn>
                <v-btn v-show="!$vuetify.breakpoint.xsOnly" fab dark small color="primary" class="makeSmall" @click="decreaseFont">
                    <v-icon dark>remove</v-icon>
                </v-btn>
                <span>
                    <label class="custom-select">
                        <select v-model="selected_language">
                            <option v-for="(language,index) in languages" :key="index">{{ language }}</option>
                        </select>
                    </label>
                </span>
                <v-menu v-show="$vuetify.breakpoint.xsOnly" bottom left offset-y>
                    <template v-show="$vuetify.breakpoint.xsOnly" v-slot:activator="{ on, attrs }">
                        <v-btn style="margin: 0px 10px;" color="white" v-show="$vuetify.breakpoint.xsOnly" v-bind="attrs" v-on="on" icon>
                            <v-icon>menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="instructions">
                        <v-list-item-title>Instruction</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="questions">
                        <v-list-item-title>Questions</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
        </v-flex>
    </v-flex>
</template>
<script>

    export default {
      computed: {
        languages(){
          return this.$store.getters.getLanguage
        },
        selected_language: {
            get(){
                return this.$store.getters.getSelectedLanguage
            },
            set(value){
                this.$store.commit('setLanguage', value)
            }
        },
        currentQuestion(){
            return this.$store.getters.getCurrentQuestionsNo
        },
        fontSize(){
            return this.$store.getters.getfontSize;
        }
      },
        methods: {
            increaseFont(){

                this.$store.commit('setfontSize', this.fontSize+1);

            },
            decreaseFont(){

                this.$store.commit('setfontSize', this.fontSize-1);

            },
            resetFont(){

                this.$store.commit('setfontSize', 16);

            },
            instructions(){
                this.$store.commit('setInstructionDialogStatus', true)
            },
            questions(){
                this.$store.commit('setQuestionsDialogStatus', true)
            },
        }

    }
</script>
<style scoped>
 #question_info {
    height: 44px;
    border-bottom: 0;
    padding: 7px 15px;
    padding-right: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: rgb(94, 164, 232);
    color: white;
 }
 .language {
     /* float: right;
     width: 12%; */
    text-align: end;
    padding: 0px 28px;
 }
 .clsForLanguage{
    float: right;
    padding:0px 15px;
 }
 #question_no {
     float: left;
 }

 .makeSmall {
    height: 22px !important;
    width: 22px !important;
    margin: 1px 6px;
    padding: 2px;
 }
  .makeSmall2 {
    height: 30px !important;
    width: 30px !important;
    margin: 1px 6px;
 }

 label.custom-select {
     position: relative;
     display: inline-block;
 }

 .custom-select select {
    display: inline-block;
    border: 1px solid #1976d2;
    padding: 4px 3px 3px 5px;
    margin: 0;
    font: inherit;
    outline: none;
    line-height: 1.2;
    background: #f8f8f8;
    -webkit-appearance: none;
    color: black;
    text-transform: capitalize;
 }

 /* for Webkit's CSS-only solution */
 @media screen and (-webkit-min-device-pixel-ratio:0) {
     .custom-select select {
         padding-right:45px;
     }
 }

 /* Select arrow styling */
 .custom-select:after {
     content: "▼";
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     font-size: 60%;
     line-height: 30px;
     padding: 0 7px;
     background: #1976d2;
     color: white;
     pointer-events:none;
 }

 .no-pointer-events .custom-select:after {
     content: none;
 }
 .padding_zero{
     padding: 0px !important;
 }


</style>
