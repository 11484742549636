import store from '../store'

export default {
    change(){
        store.commit('setActiveQuestion', store.getters.getNextQuestion)
        store.commit('setQuestionDisplayedAt', store.getters.getCurrentTime)
        this.manageResponse()
    },
    manageResponse(){

        let questionType = store.getters.getQuestionType

        if(questionType === 'SC') {

            if(store.getters.getStudentResponse === '' || store.getters.getStudentResponse === undefined) {
                store.commit('setScResponse', '')
            }
            else {
                store.commit('setScResponse', store.getters.getStudentResponse)
            }

        }

        if(questionType === 'fill') {

            if(store.getters.getStudentResponse === '' ||
                store.getters.getStudentResponse === undefined) {
                store.commit('setFillResponse', '')
            }
            else {
                store.commit('setFillResponse', store.getters.getStudentResponse)
            }

        }

        if(questionType === 'MCQ' || questionType === 'PAR') {

            if(store.getters.getStudentResponse !== undefined) {

                store.commit('initializeMcqResponse', store.getters.getStudentResponse)
            }
            else {

                store.commit('initializeMcqResponse', [])

            }

        }

        if(questionType === 'MAT') {

            if(store.getters.getStudentResponse !== undefined) {

                store.commit('setMatResponse', store.getters.getStudentResponse)
            }
            else {

                store.commit('setMatResponse', [])

            }

        }

    }
}