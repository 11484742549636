const state = {
    instructionDialog: false,
    questionsDialog: false,
    fontSize: 16
}

const mutations = {
    setInstructionDialogStatus(state, payload){
        state.instructionDialog = payload
    },
    setQuestionsDialogStatus(state, payload){
        state.questionsDialog = payload
    },
    setfontSize(state, payload){
        state.fontSize = payload
    }
}

const actions = {
    
}

const getters = {
    getInstructionDialogStatus(state){
        return state.instructionDialog
    },
    getQuestionsDialogStatus(state){
        return state.questionsDialog
    },
    getfontSize(state){
        return state.fontSize
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
