<template>
    <app-panel></app-panel>
</template>
<script>
    import appPanel from './Panel.vue'
    import QuestionTime from '../../utils/Time'
    import DatastoreResponse from '../../datastore/Response'

    export default {
        components: {
          appPanel,
        },
        computed: {
            serverTime(){
                return this.$store.getters.GetUpdatedServerTime
            },
            responseSyncedCount(){
                return this.$store.getters.getResponseSyncedCount
            }
        },
        watch: {
            serverTime(){

                this.$store.commit('setResponseSyncedCount', this.responseSyncedCount + 1)

                if(this.responseSyncedCount === 60) {

                    QuestionTime.saveTimeTaken()

                    DatastoreResponse.store()

                    this.$store.commit('setQuestionDisplayedAt', this.$store.getters.getCurrentTime)

                }

            },
        },
        mounted(){
        
            this.$eventHub.$emit("LOADING",'false')
        }
    }
</script>