import store from '../store'

export default {
    clear() {
        store.commit('clearQuestionResponse', '')
        store.commit('clearStudentResponse', '')
        store.commit('clearMarked', store.getters.getCurrentQuestionsNo)
        store.commit('clearAnswered', store.getters.getCurrentQuestionsNo)
        store.commit('clearUnAnswered', store.getters.getCurrentQuestionsNo)
    }
}