<template>
    <v-layout row justify-center>
        <v-dialog v-model="instructionDialog" persistent scrollable max-width="70%">
            <v-card>
                <v-card-title class="headline"> 
                    <v-flex>
                        <span>Exam Instructions </span>
                        <v-icon style="float:right;cursor:pointer;" @click.native="instructionDialog = false">close</v-icon>
                    </v-flex>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="text-align:center;" v-html="instruction"></v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>
<script>

    export default {
        computed: {
            instruction(){
                return this.$store.getters.getInstruction
            },
            instructionDialog: {
                get() {
                    return this.$store.getters.getInstructionDialogStatus
                },
                set(value){
                    this.$store.commit('setInstructionDialogStatus', value)
                }
            }
        },
    }
</script>