import Datastore from 'nedb'
const path = require('path')

const VueNedb = {
  
    install(Vue, options) {

        Vue.prototype.$nedb = {}
        Vue.nedb = {}
        let filename;

        if(process.env.IS_ELECTRON){

            const { app } = require('electron').remote
            let userDataPath = app.getPath('documents');
            filename = userDataPath + path.sep + 'examin' + path.sep + 'database' + path.sep + 'system.db'

        }else{

            filename = 'system.db';
        }

        Vue.prototype.$nedb.system = Vue.nedb.system = new Datastore({ filename: filename, autoload: true });
        Vue.prototype.$nedb.exams = Vue.nedb.exams = new Datastore({ filename: 'exams.db', autoload: true });
        Vue.prototype.$nedb.user = Vue.nedb.user = new Datastore({ filename: 'user.db', autoload: true });
        Vue.prototype.$nedb.response = Vue.nedb.response = new Datastore({ filename: 'response.db', autoload: true });
        Vue.prototype.$nedb.subjectWiseResult = Vue.nedb.subjectWiseResult = new Datastore({ filename: 'subjectWiseResult.db', autoload: true });
        Vue.prototype.$nedb.totalResult = Vue.nedb.totalResult = new Datastore({ filename: 'totalResult.db', autoload: true });
    }

};

export default VueNedb;