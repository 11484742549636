<template>
    
</template>
<script>
export default {
    mounted(){
        console.log("node Found")
    }   
}
</script>
<style scoped>

</style>