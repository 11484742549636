<template>
    <div>
        <div v-for="option in options" :key="option.value" style="padding: 5px">
            <app-check-box :option="option" :response="response" ref="checkbox"></app-check-box>
        </div>
    </div>
</template>
<script>

    import appCheckBox from '../controls/CheckBox'

    export default {
        components: {
            appCheckBox
        },
        computed: {
            options(){
                return this.$store.getters.getOptions.filter(x => x.text && x.text.trim() != '')
            },
            response(){
                return this.$store.getters.getMcqResponse
            }
        },
        data(){
            return {
                // response: ['a', 'b', 'c', 'd']
            }
        },
        methods:{
            SetAnswer(){
                var item = this.options[Math.floor(Math.random() * this.options.length)];
                this.$ref.checkbox.answer(item)
            }
        }
    }
</script>