<template>
    
</template>
<script>
export default {
    mounted(){
        console.log(this.$router.currentRoute.params)
    }
}
</script>
<style scoped>

</style>