import Vue from 'vue'
import ActiveQuestion from './ActiveQuestions'

const state = {
    time: {}
}

const mutations = {
    setQuestionWiseTimeTaken(state, payload){
        if(state.time[ActiveQuestion.state.question_id]) {
            let timeTaken = parseInt(state.time[ActiveQuestion.state.question_id]) + parseInt(payload)
            Vue.set(state.time, ActiveQuestion.state.question_id, timeTaken)
        }
        else {
            Vue.set(state.time, ActiveQuestion.state.question_id, payload)
        }
    },
    setTimeTaken(state, payload){
        for(let i=0; i<payload.length; i++) {

            Vue.set(state.time, payload[i].question_id, payload[i].time)

        }
    }
}

const actions = {
}

const getters = {
    getQuestionWiseTimeTaken(state){
        return state.time[ActiveQuestion.state.question_id]
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
