<template>
    <app-check-box v-model="stdResponse" :trueValue="option.value">
        <span v-html="convertText(option.text)"></span>
    </app-check-box>
</template>
<script>
    import { UiCheckbox } from 'keen-ui'
    import QuestionResponse from '../../utils/QuestionResponse'
    import ConvertQuestion from '../../utils/ConvertQuestionText'


    export default {
        props: ['option', 'response'],
        components: {
            appCheckBox: UiCheckbox
        },
        computed: {
            stdResponse: {
                get(){
                    return this.response.indexOf(this.option.value) > -1 ? this.option.value: false
                },
                set(value){
                    this.$store.commit('setMcqResponse', {status: value, value: this.option.value})
                    QuestionResponse.markQuestionStatus()
                }
            }
        },
        methods:{
            answer(response){
                // this.stdResponse = response.value;
            }
        }
    }
</script>