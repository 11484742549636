import store from '../store'
import Vue from 'vue'

export default {
    saveResponse(){
        let currentQuestion = store.getters.getCurrentQuestionsNo
        let questionResponse = store.getters.getQuestionResponse

        if(questionResponse === '' ||
            (Array.isArray(questionResponse) && questionResponse.length === 0) ) {
            store.commit('setUnAnswered', currentQuestion)
            store.commit('clearAnswered', store.getters.getCurrentQuestionsNo)
            store.commit('clearStudentResponse', '')
        }
        else {
            store.commit('setStudentResponse', questionResponse)
            store.commit('setAnswered', currentQuestion)
            store.commit('clearUnAnswered', store.getters.getCurrentQuestionsNo)
        }
    },
    markQuestionStatus(){
        let currentQuestion = store.getters.getCurrentQuestionsNo
        let questionResponse = store.getters.getQuestionResponse

        if(questionResponse === '' ||
            (Array.isArray(questionResponse) && questionResponse.length === 0) ) {
            store.commit('clearAnswered', store.getters.getCurrentQuestionsNo)
            store.commit('clearStudentResponse', '')
        }
        else {
            store.commit('setStudentResponse', questionResponse)
            store.commit('setAnswered', currentQuestion)
            store.commit('clearUnAnswered', store.getters.getCurrentQuestionsNo)
        }
        store.getters.GetMockExam ? Vue.prototype.$eventHub.$emit("SET_NEXT_QUESTION") : {}
    }
}