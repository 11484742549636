
export default {
    get(collection, query){
        return new Promise((resolve, reject) => {
            collection.find(query, function (err, docs) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(docs)
                }
            })
        })
    },
    getWithLimit(collection, query, offset, limit){
        return new Promise((resolve, reject) => {
            collection.find(query).skip(offset).limit(limit).exec(function (err, docs) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(docs)
                }
            })
        })
    },
    remove(collection, query){

        return new Promise((resolve, reject) => {
            collection.remove(query,
                { multi: true}, function (err, numRemoved) {
                    if(err){
                        reject(err)
                    }
                    else {
                        resolve(numRemoved)
                    }
                });
        })
    },
    insert(collection, payload){
        return new Promise((resolve, reject) => {
            collection.insert(payload, function (err, doc) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(doc)
                }
            })
        })
    },
    count(collection, query){
        return new Promise((resolve, reject) => {
            collection.count(query, function (err, count) {
                if(err) {
                    reject(err)
                }
                else {
                    resolve(count)
                }
            })
        })
    },
    paginate(collection, query, page, itemsPerPage){

        return new Promise((resolve, reject) => {

            this.count(collection, query)
                .then(response => {

                    let offset = (page-1)*itemsPerPage

                    let total_pages = Math.ceil(response/itemsPerPage)

                    this.getWithLimit(collection, query, offset, itemsPerPage)
                        .then(response => {
                            resolve({exams: response, pages: total_pages})
                        }, error => {
                            reject(error)
                        })

                }, error => {
                    reject(error)
                })

        })

    },
    update(collection, query, update){

        return new Promise((resolve, reject) => {

            collection.update(query, update, {}, function (err, numReplaced) {
                if(err){
                    reject(err)
                }
                else {
                    resolve(numReplaced)
                }
            });

        })

    }
}