<template>
        <div id="text_panel" v-if="isEssayExist">
            <v-layout row>
                <v-flex lg6 md6 sm12 xs12 id="essay">
                    <app-essay></app-essay>
                </v-flex>
                <v-flex lg6 md6 sm12 xs12 id="question">
                    <app-question-text></app-question-text>
                    <app-options></app-options>
                </v-flex>
            </v-layout>
        </div>

        <div id="text_panel" v-else>
            <div id="question">
                <app-question-text></app-question-text>
                <app-options></app-options>
            </div>
        </div>

</template>
<script>
    import appQuestionText from '../question/Question.vue'
    import appOptions from './OptionsPanel'
    import appEssay from '../question/Essay'


    export default {
        components: {
          appQuestionText,
          appOptions,
          appEssay
        },
        computed: {
          isEssayExist(){
              return this.$store.getters.getEssayId === false ? false : true
          }
        }
    }
</script>
<style scoped>
    #text_panel {
        height: 70% !important;
        overflow: hidden;
        background-color: white;
        border-bottom: 1px solid lightgray;
    }
    #essay {
        height: 100%;
        overflow: auto;
    }
    #question {
        height: 100%;
        overflow: auto;
    }
</style>