<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card id="backgroundcard">
            <p><q id="myquote" style="font-size:26px;">{{ Message }}</q></p>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data(){
        return { 
            dialog   : false,
            Message  : 'Contact Admin',
        }
    },
    created() {
        this.$eventHub.$on('TRANS-LOADING', this.SetLoading);
    },
    beforeDestroy() {
        this.$eventHub.$off('TRANS-LOADING');
    },
    methods: {
        SetLoading(Loading){
            this.dialog  = Loading.dialog      
            this.Message = Loading.Message
            // Loading.FinishExam ? this.$store.commit('setExamSubmitStatus', true) : {}
            // Loading.FinishExam ? this.FinishExam() : ""
        }
    }
}
</script>
<style scoped>
#backgroundcard {
    background: #ffffffeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: mymove 20s infinite;
}
</style>