<template>
    <div id="buttons_panel">
        <app-btn v-for="item in items" :key="item" :n="item"></app-btn>
    </div>
</template>
<script>
  import appBtn from './Button.vue'
  export default {
    props: ['items'],
    components: {
      appBtn
    }
  }
</script>
<style>
    #buttons_panel {
        height: 48%;
        overflow-y: auto;
        border-bottom: 1px solid lightgray;
        text-align: center;
    }
</style>