<template>
    <div>
        <v-layout row wrap>
            <v-flex lg8 md8 sm12 xs12 pa-2>
                <v-text-field
                        name="fill"
                        label="Enter Your Answer"
                        id="testing"
                        v-model="fill"
                ></v-text-field>
                <!-- <keyboard v-model="fill" layouts="123|456|789|0.{del:backspace}"></keyboard> -->
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import QuestionResponse from '../../utils/QuestionResponse'
    // import keyboard from 'vue-keyboard';

    export default {
        // components: { keyboard },
        data(){
            return{
                input: ''
            }
        },
        computed: {
            fill: {
                get(){
                    return this.$store.getters.getFillResponse
                },
                set(value){
                    this.input = value
                    this.$store.commit('setFillResponse', this.input)
                    QuestionResponse.markQuestionStatus()
                }
            }
        },
         methods:{
            SetAnswer(){
                this.fill = Math.floor(1 + Math.random() * 9)
            }
        }
    }
</script>