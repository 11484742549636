import Result from '../../datastore/Result'

const state = {
    subjectWiseResult: {},
    totalResult: {},
    selectedSubject: ''
}

const mutations = {
    setSubjectWiseResult(state, payload){
        state.subjectWiseResult = payload
    },
    setTotalResult(state, payload){
        state.totalResult = payload
    },
    setSelectedSubjectResult(state, payload){
        state.selectedSubject = payload
    }
}

const actions = {
    checkUserExamStatus(){
        return new Promise((resolve, reject) => {
            Result.result()
                .then(response => {
                    resolve()
                }, error => {
                    reject()
                })
        })
    }
}

const getters = {
    getSubjectWiseResult(state){
        return state.subjectWiseResult
    },
    getTotalResult(state){
        return state.totalResult
    },
    getSelectedSubjectResult(state){
        return state.selectedSubject
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
