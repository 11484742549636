<template>
    <span style="font-size: 20px; margin-right: 10px; margin-left: 6px;margin-top: 7px;" v-if="this.remainingTime > 0">Exam Will Start in : {{ remainingTime | timer }}</span>
</template>
<script>
    export default {
        computed: {
            remainingTime(){
                
                let time = this.$store.getters.getTimeToStart
                return time / 1000
            },
            serverTime(){

                return this.$store.getters.GetUpdatedServerTime
            }
        },
        watch: {
            serverTime(){

                this.$store.dispatch('CheckExamStatus')

            }
        },
        filters: {
            timer(time){
                let minutes = parseInt(Math.trunc(time/60));
                let seconds = parseInt(Math.trunc(time%60));

                minutes = minutes<10?'0'+minutes:minutes;
                seconds = seconds<10?'0'+seconds:seconds;

                return minutes + " : " + seconds;

            }
        }
    }
</script>