<template>
    <div>
		<v-dialog v-model="dialog" persistent :width="options.width" style="height:100%;" v-bind:style="{ zIndex: options.zIndex }">
			<v-card :color="options.color" dark>
				<v-card-text> 
                    <v-flex style="font-size: 30px;font-weight: bold;text-align: center;">Loading...</v-flex>
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
    props:['dialog'],
    data(){
        return {
            options: {
                color: 'primary',
                width: 290,
                zIndex: 200
            }        
        }
    }
}
</script>
<style scoped>

</style>