<template>
    <div style="padding: 0px 4%;">
        <app-option-sc v-if="questionType === 'SC'" ref="SC"></app-option-sc>
        <app-option-fill v-if="questionType === 'fill'" ref="fill"></app-option-fill>
        <app-option-mcq v-if="questionType === 'MCQ' || questionType === 'PAR'" ref="MCQ"></app-option-mcq>
        <app-option-mat v-if="questionType === 'MAT'" ref="MAT"></app-option-mat>
    </div>
</template>
<script>

    import appOptionSc from '../question/Sc'
    import appOptionFill from '../question/Fill'
    import appOptionMcq from '../question/Mcq'
    import appOptionMat from '../question/Mat'

    export default {
        components: {
            appOptionSc,
            appOptionFill,
            appOptionMcq,
            appOptionMat
        },
        computed: {
            questionType(){
                return this.$store.getters.getQuestionType
            },
            getQuestionId(){
                return this.$store.getters.getQuestionId
            }
        },
        watch:{
            getQuestionId(){

                this.RandomizeAnswer();    

            }
        },
        methods:{
            RandomizeAnswer(){
                
                if(this.$store.getters.GetMockExam){
                    let _this = this
                    let time = Math.floor(10000 + Math.random() * 50000)
                    setTimeout(() => _this.SetAnswer(), time);
                }
            },
            SetAnswer(){

                if(this.questionType === 'SC'){

                    this.$refs.SC.SetAnswer()

                }else if(this.questionType === 'fill'){

                    this.$refs.fill.SetAnswer()

                }else if(this.questionType === 'MCQ' || this.questionType === 'PAR'){

                    this.$refs.MCQ.SetAnswer()

                }else if(this.questionType === 'MAT'){

                    this.$refs.MAT.SetAnswer()

                }
            }
        },
        mounted(){
            if(this.$store.getters.GetMockExam){ 
                this.RandomizeAnswer()
            }
        }
    }
</script>