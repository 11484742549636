import Vue from 'vue';
import store from '../store';

if(process.env.IS_ELECTRON){ const { app } = require('electron').remote }


let _this = null

export default {

    socket: null,
    servertimer: null,
    init(socket){
        
        this.socket = socket
        this.events()
        _this = this
    },
    events(){
        this.socket.on('connect', function(){

            store.commit('SetConnectionStatus',true)
            console.log("Client Connected") 

        });

        this.socket.on('reconnect_attempt', (attemptNumber) => {

            console.log("Client Reconnected : " + attemptNumber)

        });

        this.socket.on('connect_error', (error) => {

            console.log(error)
            store.commit('SetConnectionStatus',false)

        });

        this.socket.on('disconnect', ()=>{

            let Query_Disconnect = this.socket.query ? this.socket.query.includes("temp") : []
            !Query_Disconnect ? console.log("User Disconnected") : console.log("System Disconnected")
            store.commit('SetConnectionStatus',false)
        })

        this.socket.on('response', (data)=> {

            let response = data
            let method = response['channel']
            _this[method](response['status'])

        })

    },
    close(){
        if(this.socket !== null && this.socket !== undefined) {
            this.socket.close()
        }
    },
    set_login(payload){

        this.socket.emit('login_attempted', payload)
        
    },
    'timer'(timestamp){

        console.log("Socket_Server_Time : " + new Date(timestamp))
        store.commit('SetServerTime',new Date(timestamp))
        
    },
    response_sync_individual(response){

        this.socket.emit('save-response', response)
        store.commit('setResponseSyncedCount', 0)
    },
    'saved-response'(data){

        let student = store.getters.GetUserInfo;
        
        if(data && !data.error){

            this.WriteLOGs(`${student['first_name']} Response For Question No: ${data['question_id']} Is ${data.response ? data.response : "-" } at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`)

        }else{

            this.WriteLOGs(`${student['first_name']} Response For Question No: ${data['question_id']} Is Not Saved .`)
        }
    },
    'student_exists_logout'(data){
        this.close();
    },
    'relaunch'(){

       this.ResetDBShutDown(false)

    },
    'shutdown'(){

        this.ResetDBShutDown(true)

    },
    'reset'(){

        this.ResetDBShutDown(false)
    },
    ResetDBShutDown(shutdown){

        Vue.nedb.system.remove({},{ multi: true },(err,system) =>{

            Vue.nedb.response.remove({},{ multi: true },(err,response) =>{

                Vue.nedb.subjectWiseResult.remove({},{ multi: true },(err,subjectwise) =>{

                    Vue.nedb.totalResult.remove({},{ multi: true },(err,total) =>{

                            localStorage.clear();
                            sessionStorage.clear();
                            if(process.env.IS_ELECTRON){
                                !shutdown ? app.relaunch() : {}
                                app.exit();
                            }
                    })
                })
            })
        })
    },
    'start-mock'(doc){
        store.commit('SetMockExam',true)
        store.commit('SetTempAdmin',doc)
    },
    'stop-mock'(){
        store.commit('SetMockExam',false)
    },
    WriteLOGs(log){
        let data = { file_name : new Date().toLocaleDateString().split('/').join('_'), log : log }
        this.socket.emit('write-logs',data)
    },
    SendScreenShot(data){
        this.socket.emit('send-screenshot', data)
    },
    'set-lock-sceen'(data){
        Vue.prototype.$eventHub.$emit("MESSAGE",{ dialog : false , Message : "Dont Switch To Other Applications"});
        Vue.prototype.$eventHub.$emit("TRANS-LOADING",{ dialog : true , FinishExam : true, Message : 'You have Crossed Limit of Switching Between Applications'});
    },
    'send-audio'(data){
        if(data && data['Record_URL']){
            let audio = new Audio(data['Record_URL']);
            audio.play();
        }
    },
    'notification'(data){
        Vue.prototype.$eventHub.$emit("MESSAGE",{ dialog : true , Message : data && data.Message ? data.Message : "Warning From Admin"});
    },
}