<template>
    <div class="main-container">
        <v-container fluid>
            <v-row justify="center">
                <v-col xs12 sm8 md6 lg5 xl4>
                    <v-card class="login-card">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>Student Login</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-select
                                    v-if="ShowSelectInstitution"
                                    v-model="selectedInstitution"
                                    label="Select Institution"
                                    :items="institutionList"
                                    item-text="name"
                                    item-value="institution_id"
                                ></v-select>
                                <v-text-field label="User Id" type="text" v-model="User_id"></v-text-field>
                                <v-text-field label="Password" type="password" v-model="Password"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn style="padding: 0px 30px;" color="primary" @click="VerifyUser">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-dialog v-model="dialog" persistent max-width="600">
                        <v-card id="backgroundcard" style="text-align:center;">
                            <v-card-title class="headline">Choose Exam</v-card-title>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-left">Package</th>
                                        <th class="text-left">Exam Name</th>
                                        <th class="text-left">Exam Status</th>
                                        <th class="text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in GetUserInfo['exams']" :key="item.exam_id">
                                        <td>{{ item.package_name }}</td>
                                        <td>{{ item.exam_name }} - {{ item.exam_id }}</td>
                                        <td>
                                            {{ item.exam_status === '0' ? 'New' : item.exam_status === '1' ? 'Resume Exam' : item.exam_status === '2' ? 'Completed' : 'New' }}
                                        </td>
                                        <td>
                                            <v-btn
                                                small
                                                outlined
                                                color="primary"
                                                @click="continueExam(item.exam_id)"
                                            >
                                                {{ item.exam_status === '0' ? 'Start Exam' : item.exam_status === '1' ? 'Continue Exam' : item.exam_status === '2' ? 'View Result' : 'Start Exam' }}
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-container>
        <submit style="display:none;" ref="SubmitExam"></submit>
    </div>
</template>

<script>

    import Vue   from 'vue';
    import axios from 'axios';
    import Time  from '../utils/Time';
    import DatastoreResponse from '../datastore/Response';
    import io from 'socket.io-client'
    import Socket from '../socket/Socket';
    import SystemDB from '../datastore/Exams';
    import Submit from './Buttons/Submit.vue';
    import Moment from 'moment';

    export default {
        components:{
            Submit
        },
        data(){
            return {
                Login_Data  : {},
                First_Step  : true,
                Second_Step : false,
                Download_Percentage : 0,
                User_id : "",
                Password : "",
                ExamList : [],
                selectedExam : null,
                selectedInstitution: null,
                dialog : false,
                institute: '',
            }
        },
        computed: {
            fields(){
                let Login_Data = {}
                let fields = [ {"name" : "first_name" , "label" : "Roll No" , 'type' : "text" } , {"name" : "password" , "label" : "Password" , 'type' : "password" } ]
                for(let i=0; i<fields.length; i++) {
                    Login_Data[fields[i].name] = ''
                }
                this.Login_Data = Login_Data
                return fields
            },
            GetUserInfo(){
                return { ...this.$store.getters.GetUserInfo }
            },
            institutionList(){
                return this.$store.getters.GetInstitutionList
            },
            GetInstituteDetails(){
                return this.$store.getters.GetInstituteDetails
            },
            ShowSelectInstitution(){
                return !this.$route.query.domain
            }
        },
        watch:{
            GetInstituteDetails(value){
                this.selectedInstitution = value && value['institution_id'] ? value['institution_id'] : null
            },
        },
        methods: {
            continueExam(exam_id){
                
                this.selectedExam = exam_id
                this.$store.commit('setExamId',this.selectedExam)
                this.$eventHub.$emit("LOADING",'true');
                const datetime = new Date();
                datetime.setMinutes(datetime.getMinutes());
                let exam = this.ExamList.find(x => x.exam_id == this.selectedExam)
                this.$http.post(`${this.$store.getters.GetServer}/student/validate-exam`,{ ...exam , id : this.GetUserInfo['id'] , datetime : datetime.toISOString()})
                    .then(response =>{

                        this.$store.commit('setExamDetails', datetime.toISOString() )
                        let user = { ...this.GetUserInfo }
                        user['exam_id'] = this.selectedExam;
                        this.$store.commit('SetUserInfo',user)
                        this.dialog = false;
                        this.GetNextInformation();
                    })
                    .catch(error =>{

                        this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Cannot Validate Exam Contact Admin`,"Color":"red","Close": false })
                        this.$eventHub.$emit("LOADING",'false');
                    })
            },
            GetSystem(){

                return SystemDB.get(this.$nedb.system,{})
            },
            VerifyUser() {

                this.$eventHub.$emit("LOADING",'true');
                let data = { user_id : this.User_id, user_password : this.Password, institution_id : this.selectedInstitution }
                this.$store.commit('SetInstituteDetails',this.institutionList.find(x => x.institution_id == this.selectedInstitution))
                if(!this.User_id || !this.Password || !this.selectedInstitution ){
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Enter All Fields`,"Color":"red","Close": false })
                    this.$eventHub.$emit("LOADING",'false');
                    return
                }

                this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Is Trying To Verify . at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);

                this.$http.post(`${this.$store.getters.GetServer}/student/student_login`,data)
                    .then(response =>{
                        
                        if(response && response['data'] && response['data']['response']){

                            this.$Socket.WriteLOGs(`${this.User_id} Verified Successfully at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                            this.$store.commit('SetUserInfo',response['data']['response']);
                            this.ExamList = response['data']['response']['exams']
                            Socket.close();
                            let socket = io(`${this.$store.getters.GetServer}/student` , { query: `user_id=${this.GetUserInfo['id']}&first_name=${this.GetUserInfo['user_id']}&system_no=${this.GetUserInfo['institution_id']}`})
                            Socket.init(socket);
                            this.dialog = true;
                            
                        }else if(response && response['data'] && response['data']['error']){

                            this.$Socket.WriteLOGs(`${this.User_id} Is Failed To Login`);
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${response['data']['error']['message'] ? response['data']['error']['message'] : response['data']['error']['message'] === '' ? `Contact Admin Error Code ${response['data']['error']['err_code']}` : response['data']['error'] }`,"Color":"red","Close": false })

                        }
                        this.$eventHub.$emit("LOADING",'false');
                    
                    })
                    .catch(error =>{

                        let message = error && error.response && error.response.data && error.response.data.error && error.response.data.error.message ? error.response.data.error.message : "Error Logging in"
                        this.$Socket.WriteLOGs(`${this.User_id} Is Failed To Login at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                        this.$eventHub.$emit("LOADING",'false');
                        error && error.message === "Network Error" ? this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": 'Please Check Internet Connection .',"Color":"red","Close": false }) : this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `${message}`,"Color":"red","Close": false });  
                        
                    })

            },
            VerifyPassword(){
                
                let user_info = this.GetUserInfo[Object.keys(this.Login_Data)[1]]

                if(Object.values(this.Login_Data).filter(x => x).length < 2 ){

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Failed To Verify Password at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Enter ${this.fields[1]['label']}`,"Color":"red","Close": false })
                    this.$eventHub.$emit("LOADING",'false');
                    return
                }

                if(Object.values(this.Login_Data)[1] == user_info){

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Verified Password Successfully at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);

                }else{

                    this.$Socket.WriteLOGs(`${Object.values(this.Login_Data)[0]} Failed To Verify Password at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Password Incorrect`,"Color":"red","Close": false })
                }

            },
            async GetNextInformation(){

                this.$eventHub.$emit("LOADING",'true');

                let data = { id : this.GetUserInfo['id'] , exam_id : this.selectedExam }
                this.$http.post(`${this.$store.getters.GetServer}/student/exam_info`,data)
                        .then(async (response) =>{

                            if(response && response['data'] && response['data']['response']){

                                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Successfully Fetched Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                                this.$store.commit('SetLoginTimeValidation',response['data']['response']['time_validation']);
                                this.$store.commit('SetCalculator',response['data']['response']['calculator_enable']);
                                this.$store.commit('SetCalculatorType',response['data']['response']['simple_calculator']);
                                this.$store.commit('setResumeExam',response['data']['response']['resume_exam']);
                                Time.calculateExamDuration(response['data']['response'])

                                let user = { ...this.GetUserInfo }
                                user['exam_state'] = response['data']['response']['exam_state']
                                user['exam_duration_left'] = response['data']['response']['exam_duration_left']
                                this.$store.commit('SetUserInfo',user)

                                this.ShipExamInformation();
                                await DatastoreResponse.removeAll()
                                let response_data = response['data']['response']['resume_data'].map(({ _id , __v, ...rest }) => rest);
                                await DatastoreResponse.insert(response_data)
                                this.$store.dispatch('getUserExamData',response_data);

                            }else{

                                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Fetch Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()} .`);
                                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Could Not find The Exam Information`,"Color":"red","Close": false })
                                this.$eventHub.$emit("LOADING",'false');
                            }

                        })
                        .catch(error => {

                            this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Fetch Exam Info at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                            this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Could Not find The Exam Information ${error}`,"Color":"red","Close": false })
                            this.$eventHub.$emit("LOADING",'false');

                        })
                
            },
            async ShipExamInformation(){

                const response = await this.$http({ 
                        method: 'post',
                        url: `${this.$store.getters.GetServer}/student/ship_exam`,
                        data : { "exam_id" : this.GetUserInfo['exam_id'] ? this.GetUserInfo['exam_id'] : this.selectedExam  ,"set_id" : 1 },
                        onDownloadProgress: (progressEvent) => {
                           
                           const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length');
                            if (totalLength !== null) {
                                this.Download_Percentage = Math.round( (progressEvent.loaded * 100) / totalLength )
                            }

                        }
                    })
                if(response && response['data']){

                    this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Successfully Shipped Exam at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                    const stream = response.data;
                    Time.calculateExamDuration({ exam_duration_left : this.GetUserInfo['exam_duration_left'] ? this.GetUserInfo['exam_duration_left'] :  stream.duration })
                    this.$store.dispatch('getExam', { ...stream , start_time : Moment(this.$store.getters.getExamDateTime).format('HH:MM:SS') });
                    console.log(this.GetUserInfo['exam_state'])
                    this.GetUserInfo['exam_state'] == 'start' ? this.$router.push('/instruction') : this.Resubmit()
                    
                }else{

                    this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Failed To Ship Exam`);
                }
                this.$eventHub.$emit("LOADING",'false');
            },
            SetVerifiedimage(){

                let User = this.$store.getters.GetUserInfo
                this.$http.post(`${this.$store.getters.GetServer}/student/upload_verified_image`, User )
                    .then(response =>{
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            async Resubmit(){

                this.$Socket.WriteLOGs(`${this.GetUserInfo['first_name']} Submiting at ${ new Date().toDateString() } , ${ new Date().toLocaleTimeString()}`);
                this.$eventHub.$emit('SNACK_BAR',{ "Show" : true ,"Text": `Your Responses are Saved , You Cannot Login After Exam Time , Submitting The Exam`,"Color":"green","Close": false })
                this.$refs.SubmitExam.finish(true)
            }
        },
        mounted(){
            
        }
    }

</script>
<style>

.header-contant{
    background-color: #666666;
    padding: 20px;
    width: 100%    
}
.header-contant p{
    color: white;
    margin-bottom: 0px !important;
}
.login-box{
    max-width: 400px;
    height: auto;
    box-shadow: 0px 0px 3px 0px rgb(15, 15, 15,0.4);
    margin: auto;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
}
.box-header{
    height: 40px;
    background-color: rgb(214, 214, 214);
    padding: 5px 15px;
    overflow: hidden;
}
.main-container{
    width: 100%;
    margin-top: 5%;
}

@media (max-width: 600px) {
  .main-container {
    margin-top: 10%;
  }
}

tbody td , thead th{
    text-align: left;
    padding: 5px 14px;
}

.login-card {
  max-width: 550px;
  margin: 0 auto;
}


</style>
