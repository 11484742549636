import Subject from './Subjects'
import Vue from 'vue'


const state = {
    marked: {}
}

const mutations = {
    setMarked(state, payload){
        if(state.marked[Subject.state.selected_subject] &&
            state.marked[Subject.state.selected_subject].indexOf(payload) === -1) {

            state.marked[Subject.state.selected_subject].push(payload)

        }
        else if(state.marked[Subject.state.selected_subject] &&
            state.marked[Subject.state.selected_subject].indexOf(payload) > -1) {

            return

        }
        else {

            Vue.set(state.marked, Subject.state.selected_subject, [payload])

        }
    },
    clearMarked(state, payload) {
        if(state.marked[Subject.state.selected_subject] &&
            state.marked[Subject.state.selected_subject].indexOf(payload) > -1) {

            let index = state.marked[Subject.state.selected_subject].indexOf(payload)

            state.marked[Subject.state.selected_subject].splice(index, 1);
        }

    },
    setMarkedFromStorage(state, payload){

        for(let i=0; i<payload.length; i++) {

            if (payload[i].reviewed === 1) {

                if (state.marked[payload[i].subject_id] &&
                    state.marked[payload[i].subject_id].indexOf(payload[i].question_no) === -1) {

                    state.marked[payload[i].subject_id].push(payload[i].question_no)

                }
                else if (state.marked[payload[i].subject_id] &&
                    state.marked[payload[i].subject_id].indexOf(payload[i].question_no) > -1) {

                    return

                }
                else {

                    Vue.set(state.marked, payload[i].subject_id, [payload[i].question_no])

                }
            }
        }
    }
}

const actions = {
}

const getters = {
    getMarkedQuestions(state){
        if(state.marked[Subject.state.selected_subject]) {
            return state.marked[Subject.state.selected_subject]
        }
        else {
            return []
        }
    },
    getMarkedQuestionsCount(state){
        if(state.marked[Subject.state.selected_subject]) {
            return state.marked[Subject.state.selected_subject].length;
        }
        else {
            return 0
        }
    },
    getAllMarkedQuestions(state){
        return state.marked
    },
    getAllMarkedQuestionsCount(state){
        let markedLength = 0
        for(let key in state.marked){
            markedLength += state.marked[key].length;
        }
        return markedLength
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
