<template>
    <div style="padding: 2px 15px;background-color: #5ea4e8; color: white;">
        <h4>{{ subject }}</h4>
    </div>
</template>
<script>
    export default {
        computed: {
            subject(){
                return this.$store.getters.getSelectedSubjectName
            }
        }
    }
</script>