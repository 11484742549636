<template>
      <v-container fluid>
        <v-row justify="center">
            <v-col xs12 sm10 md8 lg6 xl4>
            <!-- <v-card>
                <v-flex style="text-align:center;padding-top:10px;">
                    <v-btn @click="FinalPage" color='primary'>Continue And Proceed</v-btn>
                </v-flex>
                <v-card-title v-if="display_results">
                    <h2>Predicted Total Marks / Result</h2>
                </v-card-title>
                <v-card-text v-if="display_results">
                    <v-flex style="display: flex; text-align: center;font-weight: 700;" >
                        <v-flex xs2 ma-2 pa-2 style="border: 1px solid lightgrey;background-color: rgb(2, 136, 209); color: white;">Predicted Total Marks *</v-flex>
                        <v-flex xs2 ma-2 pa-2 style="border: 1px solid lightgrey;color: black;">{{ result_to_be_displayed }} *</v-flex>
                        <v-flex xs3 pa-1 style="font-size: 10px;">
                            <span style="position: absolute;bottom: 0;left: 0;">* Predicted Marks May Vary From The Actual Result </span>
                        </v-flex>
                    </v-flex>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title style="padding-top:0px">
                    <h2 style=" text-align: center; padding: 8px;">Answered Report</h2>
                </v-card-title>
                <v-card-text v-for="(results,index) in finalResult" :key="index+'_Subject'" style="display:inline-block;width:100%;">
                    <h3 style=" text-align: center;margin-bottom: 15px;">{{ subjects[results[0].subject_id] }}</h3>
                    <div class="mainDiv">
                        <div class="options" style=" background-color: #0288d1;color: white;">
                            <div v-for="(title,index) in Answertitles" class="innerOption" :key="index+'_Title'">  {{ title }} </div>
                        </div>
                        <div class="options" v-for="(result,index) in results" :key="index+'_result'">
                            <div class="innerOption">{{ result.question_no }}</div>
                            <div class="innerOption">{{ result.response }}</div>   
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title>
                    <h2>Subject Wise Report</h2>
                </v-card-title>
                <v-card-text>
                    <table id="customers">
                        <thead>
                            <th v-for="(title,index) in titles" :key="index+'_Custo'">
                                {{ title }}
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(result,index) in subjectWiseResult" :key="index+'_subjectwise'">
                                <td>{{ subjects[result.subject_id] }}</td>
                                <td>{{ result.attempted_questions }}</td>
                                <td>{{ result.skipped_questions }}</td>
                                <td>{{ result.time }}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-title>
                    <h2>Total Report</h2>
                </v-card-title>
                <v-card-text>
                    <table id="customers">
                        <thead>
                        <th v-for="title in total_titles" :key="title">
                            {{ title }}
                        </th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ totalResult.attempted_questions }}</td>
                            <td>{{ totalResult.skipped_questions }}</td>
                            <td>{{ totalResult.overall_time }}</td>
                        </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <v-flex style="text-align:center;">
                    <v-btn @click="FinalPage" color='primary'>Continue And Proceed</v-btn>
                </v-flex>
            </v-card> -->

                <v-card class="login-card">
                    <v-flex style="text-align:center;padding-top:10px;">
                        <v-btn @click="FinalPage" color='primary' style="margin:4px;" >Exit & Close</v-btn>
                        <v-btn @click="showAnswered" color='primary' style="margin:4px;">{{ show_answered ? 'Hide' : 'Show' }} Answered Subject List  </v-btn>
                    </v-flex>
                    <v-card-text v-if="show_answered" style="text-align: center;">
                        <span v-for="(subject, key,) in subjects" v-bind:key="key">
                            <v-btn text 
                                :outlined="selected == key"
                                color="primary"
                                style="margin: 10px 0px;"
                                @click="selectSubject(key)">
                                    {{ subject }}
                            </v-btn>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-container fluid>
                <v-row justify="center">
                    <v-dialog v-model="questionsDialog" persistent scrollable max-width="700">
                        <v-card>
                            <v-card-title class="headline">
                                <v-flex>
                                    <span>Questions </span>
                                    <v-icon style="float:right;cursor:pointer;" @click.native="questionsDialog = false;selected = null">close</v-icon>
                                </v-flex>
                            </v-card-title>
                            <v-divider style="margin:10px 0px;"></v-divider>
                            <v-card-text>
                                <div v-for="(question, i) in questions"
                                    class="question" :key="i">
                                    <div style="background: #42424217;border-radius: 5px 5px 0px 0px;padding: 0px 6px;">
                                        <v-chip label style="margin:10px 2px;" color="primary" text-color="white">{{ i+1 }}</v-chip>
                                        <v-chip label style="margin:10px 2px;" color="orange" text-color="white">{{ question.question_type | questionType }}</v-chip>
                                    </div>
                                    <v-divider style="margin:10px 0px;margin-top: 0;"></v-divider>
                                    <div v-if="question.locale[language]"
                                            v-html="convertText(question.locale[language].question_text)"></div>

                                    <div v-else>Question is not available in the selected language, please change the language</div>
                                    <div >
                                        <v-chip style="margin:2px;" color="green" text-color="white"> Correct Answer : {{  question.correct_answer  }} </v-chip>
                                        <v-chip v-if="question.student_response" style="margin:2px;" :color="question.correct_answer === question.student_response ? 'green' : 'red'" text-color="white"> Your Answer : {{  question.student_response  }} </v-chip>
                                        <v-chip v-else style="margin:2px;"> Not Answered </v-chip>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-container>
        </v-row>
  </v-container>
</template>
<script>
    import Vue from 'vue';
    import Moment from 'moment';
    export default {
        data(){
          return {
              Answertitles: ['Question', 'Response'],
              titles: ['Subject', 'Attempted', 'Skipped', 'Time Taken(sec)'],
              total_titles: ['Attempted', 'Skipped', 'Time Taken(sec)'],
              display_results : false,
              result_to_be_displayed : 0,
              show_answered : true,
              questionsDialog : false,
              questions : []

          }
        },
        filters: {
            questionType(type){
                if('fill' === type) {
                    return 'Fill in Blank'
                }

                if('MCQ' === type) {
                    return "Multiple Choice"
                }

                if('PAR' === type) {
                    return "Multiple Choice with Partial Marking"
                }

                if('SC' === type) {
                    return 'Single Choice'
                }

                if('MAT' === type) {
                    return 'Matrix'
                }
            }
        },
        computed: {
            height(){
                return (this.$store.getters.getHeight * 85) / 100
            },
            subjectWiseTable(){
                return this.$store.getters.getTotalSubjectsSavedResponse
            },
            finalResult(){
                return this.$store.getters.getTotalSavedResponse
            },
            subjectWiseResult(){
                return this.$store.getters.getSubjectWiseResult
            },
            totalResult(){
                return this.$store.getters.getTotalResult
            },
            subjects(){
                return this.$store.getters.getSubjecNames
            },
            GetDisplayedResult(){
                return this.$store.getters.GetDisplayedResult
            },
            GetUserInfo(){
                return this.$store.getters.GetUserInfo
            },
            getAllQuestions(){
                return this.$store.getters.getAllQuestions
            },
            getTotalSavedResponse(){
                return this.$store.getters.getTotalSavedResponse
            },
            language(){
                return this.$store.getters.getSelectedLanguage
            },
            selected: {
                get(){
                    return this.$store.getters.getSelectedSubjectResult
                },
                set(value){
                    this.$store.commit('setSelectedSubjectResult',value)
                }
            }
        },
        methods:{
            FinalPage(){
            // this.$router.push('/final')
                // this.$Socket.ResetDBShutDown(true)
                // window.location.reload();
                // this.$router.push('/login')

                Vue.nedb.system.remove({},{ multi: true },(err,system) =>{

                    Vue.nedb.response.remove({},{ multi: true },(err,response) =>{

                        Vue.nedb.subjectWiseResult.remove({},{ multi: true },(err,subjectwise) =>{

                            Vue.nedb.totalResult.remove({},{ multi: true },(err,total) =>{
                                    this.deleteAllCookies();
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    window.location.reload();
                                    this.$router.push('/login')
                            })
                        })
                    })
                })
            },
            deleteAllCookies() {
                const cookies = document.cookie.split(";");

                for (let i = 0; i < cookies.length; i++) {
                    const cookie = cookies[i];
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            },
            PredictResult(){

                let exam_date = Moment(this.GetUserInfo.datetime).format('YYYY-MM-DD')
                let exam_time = Moment(this.GetUserInfo.datetime).format('HH:MM')
                let exam_list = this.GetUserInfo.exam_list
                let current_exam = exam_list.find(x => {
                    if(x.start_date == exam_date && x.start_time == exam_time && x.exam_id == this.GetUserInfo.exam_id){
                        return x
                    }
                    return {}
                })
                if(current_exam.result_permission == 1 && current_exam.result_profile && current_exam.result_profile.length > 0){

                    this.display_results = true;
                    let ranges = current_exam.result_profile[0]['ranges'];
                    let total_obtained_marks = this.GetDisplayedResult.total_result.total_obtained_marks;
                    let correct_range = ranges.map(x => { let between_test = this.between(total_obtained_marks,x.l_range,x.f_range) ; 
                                                          return between_test ? x : {}}).filter(value => JSON.stringify(value) !== '{}');
                    let max_range = Math.max.apply(Math, ranges.map(function(o) { return o.f_range; }))
                    let max_correct_range = ranges.find(x => x.f_range == max_range );

                    if(correct_range && correct_range.length > 0 && total_obtained_marks > 0){

                        let final_range = correct_range[0];
                        this.result_to_be_displayed = total_obtained_marks - final_range.tolerance;

                    }else if(total_obtained_marks > 0){

                        this.result_to_be_displayed = total_obtained_marks - max_correct_range.tolerance;

                    }else{
                        
                        this.result_to_be_displayed = total_obtained_marks
                    }

                }else{

                    this.display_results = false;

                }
            },
            between(value,first,last) {

                let lower = Math.min(first,last) , upper = Math.max(first,last);
                return value >= lower && value <= upper ;

            },
            showAnswered(){
                this.show_answered = !this.show_answered;
            },
            selectSubject(subject){
                this.selected = subject
                let subject_wise_questions = this.getAllQuestions[subject];
                let subject_wise_response = this.getTotalSavedResponse[subject];
                let subject_wise_result = subject_wise_questions.map(x => {
                    let response = subject_wise_response.find(y => y.question_id == x.question_id);
                    return { ...x , student_response : response ? response.response : '' }
                })
                this.questionsDialog = true
                this.questions = subject_wise_result
            }
        },
        mounted(){
            this.$eventHub.$emit("LOADING",'false');
        }
    }
</script>
<style scoped>
    .mainDiv{
        position: relative;
        width: 100%;
    }
    .options{
        width: 9%;
        text-align: center;
        margin: 0px;
        float: left;
        height: 80px;
        border: 1px solid rgba(128, 128, 128, 0.52);
        margin-bottom: 26px;
        border-bottom: 0px;
    }
    .innerOption{
        border-bottom: 1px solid rgba(128, 128, 128, 0.52);
        padding: 8px 0px;
        width: 100%;
        height: 50%;
    }
    #customers {
        border-collapse: collapse;
        width: 100%;
    }

    #customers td, #customers th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    #customers tr:nth-child(even){background-color: #f2f2f2;}

    #customers tr:hover {background-color: #ddd;}

    #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #0288D1;
        color: white;
    }
    .question{
        margin: 10px 0px;
        border: 1px solid #d3d3d361;
        padding: 5px;
        overflow: auto;
    }
    .v-btn{
        margin: 10px 0px;
        max-width: 350px !important;
    }
    .login-card {
        max-width: 850px;
        margin: 0 auto;
    }
</style>